import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as uuid from 'uuid';

import { useModal } from '~/hooks/modal';

import { Input } from '~/components/Form/Input';
import { FormGroup } from '~/components/Form/FormGroup';
import { Button } from '~/components/Button';

import { maskPostalCode, unmask } from '~/utils/masks';

import { getAddress } from '~/services/api/viaCep';

import { IAddress } from '~/interfaces/IAddress';

interface AddressModalProps {
  onSubmit(address: IAddress): void;
  address?: IAddress;
}

interface IFormErrors {
  name: string;
  postalCode: string;
  street: string;
  neighborhood: string;
}

export const AddressModal: FC<AddressModalProps> = ({ onSubmit, address }) => {
  const { closeModal } = useModal();

  const nameRef = useRef<HTMLInputElement>(null);
  const streetRef = useRef<HTMLInputElement>(null);
  const neighborhoodRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const complementRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [postalCode, setPostalCode] = useState('');
  const [formErrors, setFormErrors] = useState({} as IFormErrors);

  useEffect(() => {
    if (address) {
      const {
        city,
        name,
        state,
        street,
        complement,
        neighborhood,
        number,
        postalCode: addressPostalCode,
      } = address;

      if (addressPostalCode) {
        setPostalCode(addressPostalCode);
      }

      if (name && nameRef.current) {
        nameRef.current.value = name;
      }

      if (state && stateRef.current) {
        stateRef.current.value = state;
      }

      if (city && cityRef.current) {
        cityRef.current.value = city;
      }

      if (street && streetRef.current) {
        streetRef.current.value = street;
      }

      if (complement && complementRef.current) {
        complementRef.current.value = complement;
      }

      if (neighborhood && neighborhoodRef.current) {
        neighborhoodRef.current.value = neighborhood;
      }

      if (number && numberRef.current) {
        numberRef.current.value = number;
      }
    }
  }, [address]);

  const handleClearError: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name } = event.target;

      if (formErrors[name]) {
        setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
      }
    },
    [formErrors]
  );

  const handleValidateFormData = (): boolean => {
    let isValid = true;

    if (!nameRef.current?.value) {
      setFormErrors((prevState) => ({
        ...prevState,
        name: 'Por favor, digite a identifição',
      }));

      isValid = false;
    }

    if (!postalCode) {
      setFormErrors((prevState) => ({
        ...prevState,
        postalCode: 'Por favor, digite o CEP',
      }));

      isValid = false;
    }

    if (postalCode && postalCode.length < 8) {
      setFormErrors((prevState) => ({
        ...prevState,
        postalCode: 'Ops, CEP inválido',
      }));

      isValid = false;
    }

    if (!streetRef.current?.value) {
      setFormErrors((prevState) => ({
        ...prevState,
        street: 'Por favor, digite o logradouro',
      }));

      isValid = false;
    }

    if (!neighborhoodRef.current?.value) {
      setFormErrors((prevState) => ({
        ...prevState,
        neighborhood: 'Por favor, digite o bairro',
      }));

      isValid = false;
    }

    return isValid;
  };

  const handleChangePostalCode: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      async (event) => {
        const { value } = event.target;

        if (formErrors.postalCode) {
          setFormErrors((prevState) => ({ ...prevState, postalCode: '' }));
        }

        const unmaskedPostalCode = unmask(value);

        setPostalCode(unmaskedPostalCode);

        if (unmaskedPostalCode.length === 8) {
          const postalCodeData = await getAddress(unmaskedPostalCode);

          if (!postalCodeData) {
            setFormErrors((prevState) => ({
              ...prevState,
              postalCode: 'Ops, CEP inválido',
            }));

            if (cityRef.current) {
              cityRef.current.value = '';
            }

            if (stateRef.current) {
              stateRef.current.value = '';
            }

            return;
          }

          const { logradouro, localidade, complemento, bairro, uf } =
            postalCodeData;

          if (logradouro && streetRef.current) {
            streetRef.current.value = logradouro;
          }

          if (bairro && neighborhoodRef.current) {
            neighborhoodRef.current.value = bairro;
          }

          if (complemento && complementRef.current) {
            complementRef.current.value = complemento;
          }

          if (localidade && cityRef.current) {
            cityRef.current.value = localidade;
          }

          if (uf && stateRef.current) {
            stateRef.current.value = uf;
          }
        } else {
          if (cityRef.current) {
            cityRef.current.value = '';
          }

          if (stateRef.current) {
            stateRef.current.value = '';
          }
        }
      },
      [formErrors.postalCode]
    );

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const formData: IAddress = {
      _id: address?._id || uuid.v4(),
      name: nameRef.current?.value,
      street: streetRef.current?.value,
      neighborhood: neighborhoodRef.current?.value,
      number: numberRef.current?.value,
      complement: complementRef.current?.value,
      city: cityRef.current?.value,
      state: stateRef.current?.value,
      postalCode,
    };

    const isFormDataValid = handleValidateFormData();

    if (!isFormDataValid) return;

    onSubmit(formData);

    closeModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Input
          id="name"
          name="name"
          placeholder="Digite a identifição"
          label="identifição"
          ref={nameRef}
          error={formErrors.name}
          onChange={handleClearError}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="postalCode"
          name="postalCode"
          placeholder="Digite o CEP"
          label="CEP"
          value={maskPostalCode(postalCode)}
          maxLength={10}
          onChange={handleChangePostalCode}
          error={formErrors.postalCode}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="street"
          name="street"
          placeholder="Digite o logradouro"
          label="logradouro"
          ref={streetRef}
          error={formErrors.street}
          onChange={handleClearError}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="neighborhood"
          name="neighborhood"
          placeholder="Digite o bairro"
          label="bairro"
          ref={neighborhoodRef}
          error={formErrors.neighborhood}
          onChange={handleClearError}
        />

        <Input
          id="number"
          name="number"
          placeholder="Digite o número"
          label="número"
          ref={numberRef}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="complement"
          name="complement"
          placeholder="Digite o complemento"
          label="complemento"
          ref={complementRef}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="city"
          name="city"
          placeholder="Digite o CEP acima"
          label="cidade"
          ref={cityRef}
          disabled
        />

        <Input
          id="state"
          name="state"
          placeholder="Digite o CEP acima"
          label="estado"
          ref={stateRef}
          disabled
        />
      </FormGroup>

      <FormGroup>
        <Button fillAllSpace size="xl" type="submit">
          Salvar
        </Button>
      </FormGroup>
    </form>
  );
};
