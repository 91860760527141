import { ICustomer } from '~/interfaces/ICustomer';

import { formatAddress } from './formatAddress';

export function formatCustomer(customer: ICustomer): ICustomer {
  const formattedAdrresses =
    customer.addresses?.length > 0
      ? customer.addresses.map((address) => formatAddress(address))
      : [];

  return {
    ...customer,
    formattedAdrresses,
  };
}
