import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useCallback,
  useState,
} from 'react';

import { useToast } from '@chakra-ui/react';

import { useStore } from '~/hooks/store';
import { useModal } from '~/hooks/modal';
import { useDrawer } from '~/hooks/drawer';
import { useAuth } from '~/hooks/auth';

import { AddressModal } from '~/components/Modals/AddressModal';
import { Input } from '~/components/Form/Input';
import { FormGroup } from '~/components/Form/FormGroup';
import { Button } from '~/components/Button';
import { Accordion } from '~/components/Accordion';

import { Container, AddressList } from './styles';

import { validatePhoneNumber } from '~/utils/validatePhoneNumber';
import { validateEmail } from '~/utils/validateEmail';
import { validateDocument } from '~/utils/validateDocument';
import { maskDocument, maskPhoneNumber, unmask } from '~/utils/masks';
import { formatAddress } from '~/utils/formatAddress';

import { IAddress } from '~/interfaces/IAddress';

interface IFormErrors {
  name: string;
  phoneNumber: string;
  document: string;
  email: string;
}

export const UserDataDrawer: FC = () => {
  const toast = useToast({
    status: 'error',
    isClosable: true,
    position: 'top-right',
  });

  const { customer, updateCustomer } = useAuth();
  const { closeDrawer } = useDrawer();
  const { openModal } = useModal();
  const { store } = useStore();

  const [formData, setFormData] = useState(customer);
  const [formErrors, setFormErrors] = useState({} as IFormErrors);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const handleDeleteAddress = useCallback((id: string) => {
    setFormData((prevState) => ({
      ...prevState,
      addresses: prevState.addresses.filter((address) => address._id !== id),
      formattedAdrresses: prevState.formattedAdrresses.filter(
        (address) => address._id !== id
      ),
    }));
  }, []);

  const handleAddAddress = useCallback((address: IAddress) => {
    setFormData((prevState) => {
      const newAddresses = [...prevState.addresses, address];

      return {
        ...prevState,
        addresses: newAddresses,
        formattedAdrresses: newAddresses.map((newAddress) =>
          formatAddress(newAddress)
        ),
      };
    });
  }, []);

  const handleUpdateAddress = useCallback((id: string, address: IAddress) => {
    setFormData((prevState) => {
      const newAddresses = prevState.addresses?.map((item) => {
        return item._id === id ? address : item;
      });

      return {
        ...prevState,
        addresses: newAddresses,
        formattedAdrresses: newAddresses.map((newAddress) =>
          formatAddress(newAddress)
        ),
      };
    });
  }, []);

  const handlePressToUpdateAddress = (id: string): void => {
    const findAddress = formData.addresses?.find(
      (address) => address._id === id
    );

    openModal(
      'Alterar endereço',
      <AddressModal
        address={findAddress}
        onSubmit={(address) => handleUpdateAddress(id, address)}
      />
    );
  };

  const handleValidateEmail = useCallback(() => {
    if (formData.email) {
      const isEmailValid = validateEmail(formData.email);

      if (!isEmailValid) {
        setFormErrors((prevState) => ({
          ...prevState,
          email: 'Ops, e-mail inválido',
        }));

        return false;
      }
    }

    return true;
  }, [formData.email]);

  const handleValidatePhoneNumber = useCallback(async () => {
    if (!formData.phoneNumber) {
      setFormErrors((prevState) => ({
        ...prevState,
        phoneNumber: 'Por favor, digite o seu número de celular',
      }));

      return false;
    }

    const isPhoneNumberValid = validatePhoneNumber(formData.phoneNumber);

    if (!isPhoneNumberValid) {
      setFormErrors((prevState) => ({
        ...prevState,
        phoneNumber: 'Ops, número de celular inválido',
      }));

      return false;
    }

    return true;
  }, [formData.phoneNumber]);

  const handleValidateDocument = useCallback(() => {
    if (store.forceDocumentFillingAtCheckout && !formData.document) {
      setFormErrors((prevState) => ({
        ...prevState,
        document: 'CPF ou CNPJ precisa ser preenchido',
      }));

      return false;
    }

    if (formData.document) {
      const isDocumentValid = validateDocument(formData.document);

      if (!isDocumentValid) {
        setFormErrors((prevState) => ({
          ...prevState,
          document: 'Ops, CPF ou CNPJ inválido',
        }));

        return false;
      }
    }

    return true;
  }, [formData.document, store.forceDocumentFillingAtCheckout]);

  const handleValidateName = useCallback(() => {
    if (!formData.name) {
      setFormErrors((prevState) => ({
        ...prevState,
        name: 'Por favor, digite o seu nome completo',
      }));

      return false;
    }

    const nameLenght = formData.name.split(' ').length;

    if (nameLenght <= 1) {
      setFormErrors((prevState) => ({
        ...prevState,
        name: 'Por favor, digite o seu nome completo',
      }));

      return false;
    }

    return true;
  }, [formData.name]);

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target;

      let newValue = value;

      if (name === 'phoneNumber' || name === 'document') {
        newValue = unmask(value);
      }

      if (formErrors[name]) {
        setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
      }

      setFormData((prevState) => ({ ...prevState, [name]: newValue }));
    },
    [formErrors]
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      setIsSubmiting(true);

      const isNameValid = handleValidateName();
      const isPhoneNumberValid = handleValidatePhoneNumber();
      const isEmailValid = handleValidateEmail();
      const isDocumentValid = handleValidateDocument();

      if (
        !isNameValid ||
        !isPhoneNumberValid ||
        !isEmailValid ||
        !isDocumentValid
      ) {
        setIsSubmiting(false);
        return;
      }

      await updateCustomer({
        name: formData.name,
        phoneNumber: formData.phoneNumber,
        addresses: formData.addresses,
        document: formData.document,
        email: formData.email,
      });

      toast({
        status: 'success',
        title: 'Boa, os dados foram alterados com sucesso!',
      });

      closeDrawer();

      setIsSubmiting(false);
    } catch (error) {
      if (
        error?.response?.data?.tag === 'CUSTOMER_PHONE_NUMBER_ALREADY_EXISTS'
      ) {
        toast({
          title: 'Ops, ocorreu um erro ao fazer o login!',
          description: 'Já existe um cliente com esse número de celular.',
        });
      }

      if (error?.response?.data?.tag === 'CUSTOMER_EMAIL_ALREADY_EXISTS') {
        toast({
          title: 'Ops, ocorreu um erro ao fazer o login!',
          description: 'Já existe um cliente com esse e-mail.',
        });
      }

      if (error?.response?.data?.tag === 'CUSTOMER_DOCUMENT_ALREADY_EXISTS') {
        toast({
          title: 'Ops, ocorreu um erro ao fazer o login!',
          description: 'Já existe um cliente com esse CPF ou CNPJ.',
        });
      }

      toast({
        title: 'Ops, ocorreu um erro ao salvar os dados!',
        description: 'Recarregue a página e tente novamente.',
      });

      setIsSubmiting(false);
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Accordion title="Dados pessoais" alwaysOpen>
        <FormGroup>
          <Input
            id="name"
            name="name"
            label="Nome completo"
            value={formData.name}
            onChange={handleChangeInput}
            error={formErrors.name}
            onBlur={handleValidateName}
            placeholder="Digite o seu nome completo"
          />
        </FormGroup>

        <FormGroup>
          <Input
            id="document"
            name="document"
            label={`CPF ou CNPJ ${
              store.forceDocumentFillingAtCheckout ? '' : '(opcional)'
            }`}
            value={maskDocument(formData.document || '')}
            onChange={handleChangeInput}
            placeholder="Digite o seu CPF ou CNPJ"
            error={formErrors.document}
            onBlur={handleValidateDocument}
            maxLength={18}
          />
        </FormGroup>
      </Accordion>

      <Accordion title="Dados de contato" alwaysOpen>
        <FormGroup>
          <Input
            id="email"
            name="email"
            label="E-mail (opcional)"
            onChange={handleChangeInput}
            value={formData.email}
            error={formErrors.email}
            onBlur={handleValidateEmail}
            placeholder="Digite o seu e-mail"
          />
        </FormGroup>

        <FormGroup>
          <Input
            id="phoneNumber"
            name="phoneNumber"
            label="Celular"
            maxLength={15}
            value={maskPhoneNumber(formData.phoneNumber)}
            onChange={handleChangeInput}
            placeholder="Digite o seu celular"
            onBlur={handleValidatePhoneNumber}
            error={formErrors.phoneNumber}
          />
        </FormGroup>
      </Accordion>

      <Accordion title="Endereços" alwaysOpen>
        {formData.addresses?.length > 0 && (
          <AddressList>
            {formData.formattedAdrresses.map((address) => (
              <li key={address._id}>
                {!!address.name && <strong>{address.name}</strong>}

                <p>{address.address}</p>

                <div>
                  <Button
                    size="sm"
                    backgroundColor="secondary"
                    textColor="onSecondary"
                    containerStyle={{ marginRight: '9px' }}
                    onClick={() => handlePressToUpdateAddress(address._id)}
                  >
                    Editar
                  </Button>
                  <Button
                    size="sm"
                    backgroundColor="danger"
                    textColor="onDanger"
                    onClick={() => handleDeleteAddress(address._id)}
                  >
                    Excluir
                  </Button>
                </div>
              </li>
            ))}
          </AddressList>
        )}

        <Button
          fillAllSpace
          backgroundColor="success"
          textColor="onSuccess"
          onClick={() =>
            openModal(
              'Adicionar endereço',
              <AddressModal onSubmit={handleAddAddress} />
            )
          }
        >
          {formData.addresses?.length > 0
            ? 'Adicionar outro endereço'
            : 'Adicionar novo endereço'}
        </Button>
      </Accordion>

      <footer>
        <Button fillAllSpace type="submit" size="lg" loading={isSubmiting}>
          Salvar
        </Button>
      </footer>
    </Container>
  );
};
