import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  memo,
  useState,
} from 'react';

import { IoEye, IoEyeOff } from 'react-icons/io5';

import { CSSProperties } from 'styled-components';
import { Spinner, Tooltip } from '@chakra-ui/react';

import { Container, InputContainer } from './styles';

import { ErrorMessage } from '../ErrorMessage';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  containerStyle?: CSSProperties;
  label?: string;
  error?: string;
  loading?: boolean;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
  { id, containerStyle, label, error, type, loading, ...props },
  ref
) => {
  const [newType, setNewType] = useState(type);
  const [isPasswordEncrypted, setIsPasswordEncrypted] = useState(true);

  function toggleIsPasswordEncrypted(): void {
    setIsPasswordEncrypted((prevState) => {
      const newState = !prevState;

      setNewType(newState ? 'password' : 'text');

      return newState;
    });
  }

  return (
    <Container style={containerStyle}>
      {label && <label htmlFor={id}>{label}</label>}

      <InputContainer error={!!error}>
        <input
          id={id}
          type={newType}
          ref={ref}
          disabled={!!loading}
          {...props}
        />

        {loading && (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}

        {type === 'password' && (
          <Tooltip
            label={isPasswordEncrypted ? 'Mostrar senha' : 'Esconder senha'}
            hasArrow
            closeOnClick={false}
          >
            <button type="button" onClick={toggleIsPasswordEncrypted}>
              {isPasswordEncrypted ? <IoEye /> : <IoEyeOff />}
            </button>
          </Tooltip>
        )}
      </InputContainer>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

const FowardedInput = forwardRef(BaseInput);

export const Input = memo(FowardedInput);
