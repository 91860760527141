import styled from 'styled-components';

interface ITextareaComponentProps {
  error?: string;
}

export const TextareaComponent = styled.textarea<ITextareaComponentProps>`
  width: 100%;
  padding: 16px;

  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme }) => theme.colors.secondBackground};

  border-color: ${(props) => props.theme.colors.secondBackground};
  border-color: ${(props) => props.error && props.theme.colors.danger};

  resize: none;

  font-size: 14px;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.onBackground};
`;
