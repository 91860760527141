import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export interface EnviromentVariables {
  API_URI: string;
  REVALIDATE_TIME: number;
}

export const env: EnviromentVariables = publicRuntimeConfig;
