import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { MdClose } from 'react-icons/md';

import { useTheme } from 'styled-components';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  Tooltip,
} from '@chakra-ui/react';

import { StyledModalBody } from './styles';

interface IProps {
  title: string;
  children: ReactNode;
  noCloseOnclickOut: boolean;
}

export interface IModalHandles {
  open(): void;
  close(): void;
}

const BaseModal: ForwardRefRenderFunction<IModalHandles, IProps> = (
  { title, children, noCloseOnclickOut },
  ref
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [noCloseOnclickOut]);

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    [onOpen, onClose]
  );

  return (
    <>
      <ChakraModal
        isOpen={isOpen}
        onClose={
          noCloseOnclickOut
            ? () => {
                console.log();
              }
            : onClose
        }
        id="chakra-modal-global-style"
      >
        <ModalOverlay />

        <ModalContent style={{ background: theme.colors.background }}>
          <StyledModalBody>
            <header>
              <h3>{title}</h3>

              {!noCloseOnclickOut && (
                <Tooltip label="Fechar" hasArrow closeOnClick={false}>
                  <button type="button" onClick={onClose}>
                    <MdClose />
                  </button>
                </Tooltip>
              )}
            </header>
            {children}
          </StyledModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export const Modal = forwardRef(BaseModal);
