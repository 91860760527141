import styled from 'styled-components';

interface StoreIsOpenTopBarProps {
  isOpen: boolean;
}

export const Container = styled.header`
  margin-bottom: 20px;
`;

export const StoreIsOpenTopBar = styled.div<StoreIsOpenTopBarProps>`
  position: fixed;
  top: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  background: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.success : theme.colors.danger};

  padding: 4px 0;
  margin-bottom: 5px;

  span {
    color: ${({ theme, isOpen }) =>
      isOpen ? theme.colors.onSuccess : theme.colors.onDanger};
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.15rem;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 36px !important;

  .menu-button {
    position: absolute;
    left: ${({ theme }) => theme.spacing};
    top: 0;

    color: ${({ theme }) => theme.colors.onBackground};

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 30px;
      height: 30px;
    }

    @media (max-width: ${({ theme }) => theme.mobileWidth}) {
      left: ${({ theme }) => theme.mobileSpacing};
    }
  }

  .opening-status-container {
    display: flex;
    justify-content: flex-end;

    width: 100%;

    .opening-status {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-bottom: 12px;

      .status-ball {
        width: 14px;
        height: 14px;

        background: ${({ theme }) => theme.colors.success};
        border-radius: 50%;

        margin-right: 5px;
      }

      .closed {
        background: ${({ theme }) => theme.colors.danger};
      }

      span {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 12px;
        color: ${({ theme }) => theme.colors.onBackground};
      }
    }
  }

  .input-div {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 700px;
  }

  @media (max-width: ${({ theme }) => theme.mobileWidth}) {
    margin-top: 16px !important;
    flex-direction: column;
  }
`;

export const NavItems = styled.div`
  width: 270px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .nav-button {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-right: 18px;
    cursor: pointer;

    transition: opacity 0.3s;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.6;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      top: -8px;
      right: 3px;

      background: ${({ theme }) => theme.colors.secondary};
      width: 17px;
      height: 17px;
      border-radius: 100%;

      font-size: 10px;
      font-weight: 700;
    }

    svg {
      width: 20px;
      height: 20px;

      color: ${({ theme }) => theme.colors.onBackground};

      margin-bottom: 4px;
    }

    p {
      color: ${({ theme }) => theme.colors.onBackground};
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
    }
  }
`;
