import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    color: ${({ theme }) => theme.colors.onBackground};
    font-size: 16px;

    margin-bottom: 16px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-top: 16px;

    div {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
`;
