import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';

import { Image } from '~/components/Image';

import { Container } from './styles';

interface IProps extends HTMLAttributes<HTMLAnchorElement> {
  logo: string;
  size?: 'sm' | 'md' | 'xl';
}

const BaseLogoContainer: ForwardRefRenderFunction<HTMLAnchorElement, IProps> = (
  { logo, size = 'md', ...props },
  ref
) => {
  const imageSize =
    size === 'sm' ? 130 : size === 'md' ? 145 : size === 'xl' ? 160 : 150;

  return (
    <Container imageSize={imageSize} {...props} ref={ref}>
      {logo ? (
        <Image
          src={logo}
          alt="Logo"
          width={imageSize}
          height={imageSize}
          objectFit="contain"
        />
      ) : (
        <div className="without-logo">Logo</div>
      )}
    </Container>
  );
};

export const LogoContainer = forwardRef(BaseLogoContainer);
