import { minPriceCombo } from '~/utils/minPriceCombo';

import { api, IApiResponse } from '~/services/api';

import { IProduct } from '~/interfaces/IProduct';

interface IParams {
  categoryId?: string;
  name?: string;
  showWithoutCategories?: boolean;
}

export async function loadProduct(id: string): Promise<IProduct> {
  const { data } = await api.get(`/products/${id}`);

  if (data.price === 0 && data.comboJourneys && data.comboJourneys.length > 0) {
    return { ...data, price: minPriceCombo(data), isComboWithinValue: true };
  }
  return data;
}

export async function loadProducts(
  params: IParams
): Promise<IApiResponse<IProduct[]>> {
  try {
    const response = await api.get('/products', { params });

    const productsFiltereds = response.data.filter(
      (product: IProduct) => product?.comboType !== 'carvery'
    );

    const productsMapping = productsFiltereds.map((prod: IProduct) => {
      if (
        prod.price === 0 &&
        prod.comboJourneys &&
        prod.comboJourneys.length > 0
      ) {
        return {
          ...prod,
          price: minPriceCombo(prod),
          isComboWithinValue: true,
        };
      }
      return prod;
    });

    return {
      data: productsMapping,
    };
  } catch (err) {
    return {
      error: err?.response?.data,
    };
  }
}

export async function loadPromotionalProducts(): Promise<
  IApiResponse<IProduct[]>
> {
  try {
    const response = await api.get('/products/promotions');

    const productsFiltereds = response.data.filter(
      (product: IProduct) => product?.comboType !== 'carvery'
    );

    const productsMapping = productsFiltereds.map((prod: IProduct) => {
      if (
        prod.price === 0 &&
        prod.comboJourneys &&
        prod.comboJourneys.length > 0
      ) {
        return {
          ...prod,
          price: minPriceCombo(prod),
          isComboWithinValue: true,
        };
      }
      return prod;
    });

    return {
      data: productsMapping,
    };
  } catch (err) {
    return {
      error: err?.response?.data,
    };
  }
}

export async function loadBestSellingProducts(): Promise<
  IApiResponse<IProduct[]>
> {
  try {
    const response = await api.get('/products/best-sellers');

    const productsFiltereds = response.data.filter(
      (product: IProduct) => product?.comboType !== 'carvery'
    );

    const productsMapping = productsFiltereds.map((prod: IProduct) => {
      if (
        prod.price === 0 &&
        prod.comboJourneys &&
        prod.comboJourneys.length > 0
      ) {
        return {
          ...prod,
          price: minPriceCombo(prod),
          isComboWithinValue: true,
        };
      }
      return prod;
    });

    return {
      data: productsMapping,
    };
  } catch (err) {
    return {
      error: err?.response?.data,
    };
  }
}
