import {
  FC,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import { useRouter } from 'next/router';

import { parseCookies } from 'nookies';
import { useToast } from '@chakra-ui/react';

import { storages } from '~/constants/storages';

import { StoreSEO } from '~/components/StoreSEO';

import { formatStore } from '~/utils/formatStore';

import { checkStoreIsOpen } from '~/services/store';

import { IStore } from '~/interfaces/IStore';

interface StoreProviderProps {
  store: IStore;
}

interface IStoreContextData {
  store: IStore;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const StoreContext = createContext({} as IStoreContextData);

const StoreProvider: FC<StoreProviderProps> = ({ children, store }) => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    status: 'error',
    title: 'Ops, ocorreu um erro!',
  });
  const { pathname } = useRouter();
  const formattedStore = useMemo(() => formatStore(store), [store]);

  const { [storages.STORE_ID]: storagedStoreId } = parseCookies();

  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    if (storagedStoreId) {
      (async () => {
        const { data, error } = await checkStoreIsOpen();

        if (error) {
          // toast({
          //   description:
          //     'Ao tentar verificar se a loja está dentro do horário de funcionamento.',
          // });

          return;
        }

        if (isOpen !== data && typeof data === 'boolean') setIsOpen(data);
      })();
    }
  }, [isOpen, storagedStoreId, toast]);

  return (
    <StoreContext.Provider value={{ store: formattedStore, isOpen, setIsOpen }}>
      {!pathname.includes('/products') && <StoreSEO />}

      {children}
    </StoreContext.Provider>
  );
};

const useStore = (): IStoreContextData => {
  return useContext(StoreContext);
};

export { StoreProvider, useStore };
