import { FC, useCallback, useRef, useState } from 'react';

import { useRouter } from 'next/dist/client/router';

import { parseCookies, setCookie } from 'nookies';

import { storages } from '~/constants/storages';
import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useModal } from '~/hooks/modal';
import { useForm } from '~/hooks/form';
import { useAuth } from '~/hooks/auth';

import { Textarea } from '~/components/Textarea';
import { Button } from '~/components/Button';

import { Container } from './styles';

export const NoteModal: FC = () => {
  const { push } = useRouter();
  const { closeModal } = useModal();
  const { setFormData } = useForm();
  const { store } = useStore();
  const { isAuthenticated, customer } = useAuth();

  const noteRef = useRef(null);

  const [noteError, setNoteError] = useState<string>('');

  const { [storages.FORM_DATA(store?.tag)]: storagedFormData } = parseCookies();
  const parsedFormData = JSON.parse(storagedFormData || '{}');

  const handleSubmit = useCallback(
    (fillNote: boolean) => {
      if (store?.forceNoteFillingAtCheckout && !noteRef.current?.value) {
        setNoteError('Preencha a observação para continuar!');

        return;
      }

      if (fillNote) {
        setFormData((prevState) => ({
          ...prevState,
          note: noteRef.current?.value,
        }));

        setCookie(
          null,
          storages.FORM_DATA(store?.tag),
          JSON.stringify({
            ...parsedFormData,
            note: noteRef.current?.value,
          }),
          {
            path: '/',
          }
        );
      }

      if (isAuthenticated) {
        if (
          store?.showDocumentAtCheckout &&
          store?.forceDocumentFillingAtCheckout &&
          !customer.document
        ) {
          push(routes.register.document(store.tag));
        } else {
          push(routes.checkout.shippingMethod(store.tag));
        }
      } else {
        push(routes.auth.phoneVerification(store.tag));
      }

      closeModal();
    },
    [
      closeModal,
      customer,
      isAuthenticated,
      parsedFormData,
      push,
      setFormData,
      store,
    ]
  );

  return (
    <Container>
      <h1>Adicionar alguma observação geral no pedido?</h1>

      <Textarea
        ref={noteRef}
        placeholder={store?.notePlaceholder || ''}
        error={noteError}
        onChange={() => setNoteError('')}
      />

      <div>
        {!store?.forceNoteFillingAtCheckout && (
          <Button size="lg" onClick={() => handleSubmit(false)}>
            Não, obrigado
          </Button>
        )}
        <Button
          size="lg"
          backgroundColor="secondary"
          textColor="onSecondary"
          onClick={() => handleSubmit(true)}
        >
          Adicionar
        </Button>
      </div>
    </Container>
  );
};
