import axios from 'axios';

interface IViacepAddress {
  erro?: boolean;
  cep: string;
  complemento: string;
  localidade: string;
  ibge: string;
  uf: string;
  bairro: string;
  logradouro: string;
}

export const getAddress = async (
  cep: string
): Promise<IViacepAddress | undefined> => {
  try {
    const formattedCep = cep.replace(/\D/g, '');

    const response = await axios.get<IViacepAddress>(
      `https://viacep.com.br/ws/${formattedCep}/json`
    );

    if (response.data.erro) {
      return undefined;
    }

    return response.data;
  } catch (error) {
    return undefined;
  }
};
