import {
  FC,
  createContext,
  useContext,
  useState,
  useRef,
  useCallback,
  ReactNode,
} from 'react';

import { Drawer, IDrawerHandles } from '~/components/Drawers/Drawer';

interface IDrawerContextData {
  openDrawer(drawerTitle: string, drawerContent: ReactNode): void;
  closeDrawer(): void;
}

const DrawerContext = createContext({} as IDrawerContextData);

const DrawerProvider: FC = ({ children }) => {
  const drawerRef = useRef<IDrawerHandles>(null);

  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<ReactNode>(null);

  const openDrawer = useCallback((drawerTitle, drawerContent) => {
    drawerRef.current?.open();
    setTitle(drawerTitle);
    setContent(drawerContent);
  }, []);

  const closeDrawer = useCallback(() => {
    drawerRef.current?.close();
    setTitle('');
    setContent(null);
  }, []);

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      {children}

      <Drawer ref={drawerRef} title={title}>
        {content}
      </Drawer>
    </DrawerContext.Provider>
  );
};

const useDrawer = (): IDrawerContextData => {
  return useContext(DrawerContext);
};

export { DrawerProvider, useDrawer };
