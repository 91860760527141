export enum OrderStatusEnum {
  'processing' = 'processing',
  'awaiting_approval' = 'awaiting_approval',
  'awaiting_payment' = 'awaiting_payment',
  'approved' = 'approved',
  'rejected' = 'rejected',
  'canceled' = 'canceled',
  'shipped' = 'shipped',
  'delivered' = 'delivered',
  'finished' = 'finished',
  'shipping_problem' = 'shipping_problem',
}

export enum OrderFormattedStatusEnum {
  'processing' = 'Processando pedido',
  'awaiting_approval' = 'Aguardando aprovação',
  'awaiting_payment' = 'Aguardando pagamento',
  'approved' = 'Pedido aprovado',
  'rejected' = 'Pedido rejeitado',
  'canceled' = 'Pedido cancelado',
  'shipped' = 'Pedido enviado',
  'delivered' = 'Pedido entregue',
  'finished' = 'Pedido concluído',
  'shipping_problem' = 'Problema na entrega',
}
