import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { useTheme } from 'styled-components';
import { setCookie } from 'nookies';

import { storages } from '~/constants/storages';
import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useModal } from '~/hooks/modal';
import { useAuth } from '~/hooks/auth';

import { Input } from '~/components/Form/Input';
import { FormGroup } from '~/components/Form/FormGroup';
import { Button } from '~/components/Button';

import { maskPhoneNumber, unmask } from '~/utils/masks';

import { CheckoutTypeEnum } from '~/enums/CheckoutTypeEnum';

import { CustomerSignInRequestDTO } from '~/dtos/CustomerSignInRequestDTO';

interface IFormErrors {
  phoneNumber: string;
  password: string;
}

export const SignInModal: FC = () => {
  const theme = useTheme();
  const { push } = useRouter();
  const { signIn } = useAuth();
  const { closeModal } = useModal();
  const { store } = useStore();

  const passwordRef = useRef<HTMLInputElement>(null);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [formErrors, setFormErrors] = useState({} as IFormErrors);
  const [isSigning, setIsSigning] = useState(false);

  const handleChangePhoneNumber: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const { value, name } = event.target;

        if (formErrors[name]) {
          setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
        }

        setPhoneNumber(unmask(value));
      },
      [formErrors]
    );

  const handleChangePassword: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        const { name } = event.target;

        if (formErrors[name]) {
          setFormErrors((prevState) => ({ ...prevState, [name]: '' }));
        }
      },
      [formErrors]
    );

  const handleSignIn: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      setIsSigning(true);

      const signInCredentials: CustomerSignInRequestDTO = {
        phoneNumber,
        password: passwordRef.current?.value,
      };

      if (!signInCredentials.phoneNumber) {
        setFormErrors((prevState) => ({
          ...prevState,
          phoneNumber: 'Por favor, digite o número de celular',
        }));
      }

      if (!signInCredentials.password) {
        setFormErrors((prevState) => ({
          ...prevState,
          password: 'Por favor, digite a senha',
        }));
      }

      if (!signInCredentials.phoneNumber || !signInCredentials.password) {
        setIsSigning(false);
        return;
      }

      await signIn(signInCredentials);

      setIsSigning(false);

      closeModal();
    } catch (error) {
      setIsSigning(false);
    }
  };

  function handleForgotPassword(): void {
    setCookie(
      null,
      storages.CHECKOUT_TYPE(store?.tag),
      CheckoutTypeEnum.passwordRecovery,
      {
        path: '/',
      }
    );
    push(routes.auth.phoneVerification(store.tag));
    closeModal();
  }

  return (
    <form onSubmit={handleSignIn}>
      <FormGroup>
        <Input
          id="phoneNumber"
          name="phoneNumber"
          label="Número de celular"
          placeholder="Digite o seu número de celular com DDD"
          value={maskPhoneNumber(phoneNumber)}
          error={formErrors.phoneNumber}
          onChange={handleChangePhoneNumber}
          maxLength={15}
        />
      </FormGroup>

      <FormGroup>
        <Input
          id="password"
          name="password"
          label="Senha"
          placeholder="Digite a sua senha"
          type="password"
          ref={passwordRef}
          error={formErrors.password}
          onChange={handleChangePassword}
        />
      </FormGroup>

      <FormGroup>
        <button
          style={{ color: theme.colors.onBackground }}
          type="button"
          onClick={() => handleForgotPassword()}
        >
          Esqueceu a senha?
        </button>
      </FormGroup>

      <FormGroup>
        <Button type="submit" size="lg" fillAllSpace loading={isSigning}>
          Entrar
        </Button>
      </FormGroup>
    </form>
  );
};
