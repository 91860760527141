import { FC, createContext, useContext } from 'react';

interface ILayoutsContextData {
  secondLayoutId: string;
}

const LayoutsContext = createContext({} as ILayoutsContextData);

const LayoutsProvider: FC = ({ children }) => {
  const secondLayoutId = '8ab94f59-be9b-4e74-804e-5229fe053d00';

  return (
    <LayoutsContext.Provider value={{ secondLayoutId }}>
      {children}
    </LayoutsContext.Provider>
  );
};

const useLayouts = (): ILayoutsContextData => {
  return useContext(LayoutsContext);
};

export { LayoutsProvider, useLayouts };
