import { IStore } from '~/interfaces/IStore';

import { formatAddress } from './formatAddress';

export function formatStore(store: IStore): IStore {
  const formattedAddresses =
    store?.addresses?.length > 0
      ? store.addresses.map((address) => formatAddress(address))
      : [];

  return {
    ...store,
    formattedAddresses,
  };
}
