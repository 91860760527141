import styled from 'styled-components';

export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  gap: 24px;

  > * {
    min-width: 130px;
    flex: 1;
  }

  & + & {
    margin-top: 24px;
  }
`;
