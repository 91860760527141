import {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  TextareaHTMLAttributes,
} from 'react';

import { TextareaComponent } from './styles';

import { ErrorMessage } from '../Form/ErrorMessage';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

const BaseTextarea: ForwardRefRenderFunction<HTMLTextAreaElement, IProps> = (
  { error, ...props },
  ref
) => {
  return (
    <>
      <TextareaComponent error={error} rows={8} ref={ref} {...props} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

const ForwardTextarea = forwardRef(BaseTextarea);

export const Textarea = memo(ForwardTextarea);
