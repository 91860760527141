import { FC } from 'react';

import Image from 'next/image';

import { useStore } from '~/hooks/store';

import { LogoContainer } from '~/components/LogoContainer';

import { Container, Background, Content } from './styles';

import { StoreActivityBranchEnum } from '~/enums/StoreActivityBranchEnum';

import poweredBy from '../../../public/images/powered-by.png';

export const FormLayout: FC = ({ children }) => {
  const { store } = useStore();

  const backgroundImage =
    store.activityBranch === StoreActivityBranchEnum.food
      ? 'food-background.svg'
      : 'generic-background.svg';

  return (
    <Container>
      <Background image={backgroundImage}>
        <div className="background-image">
          <Image src={poweredBy} alt="powered-by" width={120} height={83} />
        </div>
      </Background>

      <Content>
        <LogoContainer
          size="xl"
          logo={store?.logo}
          style={{ marginBottom: '32px' }}
        />

        {children}
      </Content>
    </Container>
  );
};
