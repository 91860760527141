/* eslint-disable react/no-unescaped-entities */
import { FC } from 'react';

import { Accordion } from '~/components/Accordion';

import { HelpText } from './styles';

export const HelpDrawer: FC = () => {
  return (
    <>
      <Accordion title="Como cancelo o meu pedido?">
        <HelpText>
          Primeiramente clique em "Meus Pedidos" depois, encontre o pedido que
          deseja cancelar e clique em "Ajuda" clique no ícone do Whastapp,
          explique o problema e na sequencia solicite o cancelamento.
        </HelpText>
      </Accordion>

      <Accordion title="É seguro inserir os dados do meu cartão?">
        <HelpText>
          É importante que você saiba que os cartões ficam salvos apenas no seu
          dispositivo (celular, notebook, tablet) usado pra cadastrá-los e
          sempre será solicitado as informações como validade e código de
          verificação, ou seja, não salvamos as informações do seu cartão. A
          recomendação assim como em todas as plataformas é sempre usar o cartão
          virtual para realizar compras onlines.
        </HelpText>
      </Accordion>

      <Accordion title="Como usar o meu cupom de desconto?">
        <HelpText>
          Ao chegar na etapa da forma de pagamento (dinheiro ou cartão), clique
          em “adicionar cupom de desconto”, informe o código fornecido e clique
          em “adicionar”, automaticamente será aplicado o desconto.
        </HelpText>
      </Accordion>

      <Accordion title="Como faço para ser parceiro?">
        <HelpText>
          Acesse{' '}
          <a href="https://menufacil.app/" target="_blank" rel="noreferrer">
            https://menufacil.app/
          </a>{' '}
          navegue pela página e descubra as vantagens em ser um parceiro MENU
          FÁCIL, crie sua conta, simples e fácil!
        </HelpText>
      </Accordion>

      <Accordion title="Como eu altero minha senha?">
        <HelpText>
          Na tela de login clique em "esqueci a senha" informe o número do
          celular cadastrado e selecione por onde deseja receber o código de
          validação (Whatsapp ou SMS), digite o código recebido e redefina a
          senha.
        </HelpText>
      </Accordion>
    </>
  );
};
