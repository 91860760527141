const prefix = 'menufacil';

export const storages = {
  STORE_ID: `${prefix}.store-id`,
  FORM_DATA: (storeTag: string): string => `${prefix}.${storeTag}.form-data`,
  CART: (storeTag: string): string => `${prefix}.${storeTag}.cart`,
  CHECKOUT_TYPE: (storeTag: string): string =>
    `${prefix}.${storeTag}.checkout-type`,
  ORDER: (storeTag: string): string => `${prefix}.${storeTag}.order`,
  AUTH_TOKEN: `${prefix}.auth-token`,
};
