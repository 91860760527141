import styled, { ColorScheme, css } from 'styled-components';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';

interface HeaderProps {
  alwaysOpen?: boolean;
  color: ColorScheme;
  isDisabled: boolean;
  storeTheme: StoreThemeEnum;
  isContentOpen: boolean;
  size?: 'sm' | 'md';
  invertColors?: boolean;
}

export const Container = styled.section`
  & + &,
  & + #form-group {
    margin-top: 20px;
  }

  > div:first-child {
    box-shadow: 3px 3px 15px #00000010;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    overflow: hidden;
  }
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  background: ${({ theme }) => theme.colors.secondBackground};
  color: #fff;
  padding: ${({ size }) => (size === 'sm' ? '13px' : '15px')} 20px;

  border-top-left-radius: ${({ theme }) => theme.borderRadius.sm};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.sm};

  transition: all 0.4s;
  cursor: default;

  border-bottom: ${(props) =>
    props.isContentOpen &&
    (props.storeTheme === StoreThemeEnum.dark
      ? `1px solid ${props.theme.colors.primary}`
      : '1px solid #eee')};

  h3 {
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-size: ${({ size }) => (size === 'sm' ? '12px' : '13px')};
    margin-right: auto;
    font-weight: 700;
  }

  > div {
    transition: all 0.4s;

    svg {
      width: 19px;
      height: 19px;
      color: ${({ theme }) => theme.colors.primary};
      pointer-events: none;
    }

    & + div {
      margin-left: 12px;
    }
  }

  ${({ alwaysOpen }) =>
    !alwaysOpen &&
    css`
      cursor: pointer;

      &:hover {
        filter: brightness(0.95);

        @media (max-width: 600px) {
          filter: brightness(1);
        }
      }
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      opacity: 0.8;

      &:hover {
        filter: brightness(1);
      }
    `}

  ${({ invertColors }) =>
    invertColors &&
    css`
      background: ${({ theme }) => theme.colors.primary};
      border-bottom: none;

      h3 {
        color: ${({ theme }) => theme.colors.secondBackground};
      }

      > div {
        svg {
          color: ${({ theme }) => theme.colors.secondBackground};
        }
      }
    `}

  @media (max-width: 500px) {
    padding: ${({ size }) => (size === 'sm' ? '13px' : '15px')} 15px;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => `${theme.colors.secondBackground} !important`};
  padding: 20px;

  @media (max-width: 500px) {
    padding: 15px;
  }
`;
