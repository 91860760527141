import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    margin-bottom: 20px;

    h1 {
      font-size: 16px;
      text-align: center;
      color: ${({ theme }) => theme.colors.onBackground};
      margin-top: 16px;
    }

    p {
      font-size: 14px;
      text-align: center;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.onBackground};
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 30px;

    .socials {
      a {
        transition: all 0.4s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    svg {
      width: 35px;
      height: 35px;

      color: ${({ theme }) => theme.colors.onBackground};

      &:first-child {
        margin-right: 3px;
      }

      &:last-child {
        margin-left: 3px;
      }
    }

    span {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.onBackground};
    }
  }
`;

export const InfoList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      min-width: 20px;
      min-height: 20px;

      margin-right: 10px;

      color: ${({ theme }) => theme.colors.onBackground};
    }

    span {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.onBackground};
    }

    strong {
      font-size: 13px;
      display: block;
      color: ${({ theme }) => theme.colors.onBackground};
    }
  }

  .col {
    flex-direction: column;
  }
`;
