import { FC } from 'react';

import { ThemeProvider } from 'styled-components';

import { lightColors } from '~/styles/lightColors';
import { darkColors } from '~/styles/darkColors';
import { theme as styledTheme } from '../styles/theme';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';

import { useStore } from './store';

export const ThemeConfigsProvider: FC = ({ children }) => {
  const { store } = useStore();

  const storeTheme = store?.theme;

  const theme = {
    ...styledTheme,
    ...(storeTheme === StoreThemeEnum.dark ? darkColors : lightColors),
  };

  // @ts-ignore
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
