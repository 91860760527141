import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { MdClose } from 'react-icons/md';

import { useTheme } from 'styled-components';
import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react';

import { useMediaQuery } from '~/hooks/mediaQuery';

import { StyledDrawerBody } from './styles';

export type IPlacement = 'top' | 'right' | 'bottom' | 'left';

interface IProps {
  title: string;
  children: ReactNode;
}

export interface IDrawerHandles {
  open(): void;
  close(): void;
}

const BaseDrawer: ForwardRefRenderFunction<IDrawerHandles, IProps> = (
  { title, children },
  ref
) => {
  const theme = useTheme();

  const isSmallerThan650px = useMediaQuery('(max-width: 651px)');
  const placement: IPlacement = isSmallerThan650px ? 'bottom' : 'right';

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: onOpen,
      close: onClose,
    }),
    [onOpen, onClose]
  );

  return (
    <>
      <ChakraDrawer
        isOpen={isOpen}
        placement={placement}
        onClose={() => setIsOpen(false)}
        id="chakra-drawer-global-style"
      >
        <DrawerOverlay />

        <DrawerContent style={{ background: theme.colors.background }}>
          <StyledDrawerBody>
            <header>
              <h3>{title}</h3>

              <button type="button" onClick={onClose}>
                <MdClose />
              </button>
            </header>

            <div className="content">{children}</div>
          </StyledDrawerBody>
        </DrawerContent>
      </ChakraDrawer>
    </>
  );
};

export const Drawer = forwardRef(BaseDrawer);
