import {
  FC,
  createContext,
  useContext,
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';

import { useRouter } from 'next/router';

import { v4 as uuidV4 } from 'uuid';
import { parseCookies, setCookie } from 'nookies';
import { useToast } from '@chakra-ui/react';

import { storages } from '~/constants/storages';
import { routes } from '~/constants/routes';

import { createOrder } from '~/services/orders';

import { OrderShippingMethodEnum } from '~/enums/OrderShippingMethodEnum';
import { OrderPaymentMethodEnum } from '~/enums/OrderPaymentMethodEnum';

import { OrderItemRequestDTO, OrderRequestDTO } from '~/dtos/OrderRequestDTO';

import { useStore } from './store';
import { useForm } from './form';
import { useFacebookPixel } from './facebookPixel';
import { useCart } from './cart';
import { useAuth } from './auth';

export enum SubmitOrderLoadingEnum {
  withoutChange = 'withoutChange',
  withChange = 'withChange',
  hasLoading = 'hasLoading',
}

interface IOrdersContextData {
  handleCreateOrder(
    withoutCard?: boolean,
    installments?: number,
    changeFor?: number
  ): Promise<void>;
  submitOrderLoading: SubmitOrderLoadingEnum | null;
  setSubmitOrderLoading: Dispatch<
    SetStateAction<SubmitOrderLoadingEnum | null>
  >;
  orderTotalAmount: number;
  refreshOrderId(): void;
}

const OrdersContext = createContext({} as IOrdersContextData);

const OrdersProvider: FC = ({ children }) => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
  });
  const { query, push } = useRouter();
  const { cart, setCart, cleanCart, cartTotals } = useCart();
  const { formData } = useForm();
  const { track } = useFacebookPixel();
  const { isAuthenticated, customer } = useAuth();
  const { store } = useStore();

  const [submitOrderLoading, setSubmitOrderLoading] =
    useState<SubmitOrderLoadingEnum | null>(null);

  const { [storages.FORM_DATA(store?.tag)]: storagedFormData } = parseCookies();
  const parsedFormData = JSON.parse(storagedFormData || '{}');

  const orderId = useRef(uuidV4());

  const deliveryAmount =
    parsedFormData?.shipping?.method === OrderShippingMethodEnum.withdraw
      ? 0
      : parsedFormData.shipping?.option?.price;

  const orderTotalAmount = (deliveryAmount || 0) + cartTotals.price;

  const refreshOrderId = useCallback(() => {
    orderId.current = uuidV4();
  }, []);

  const handleCreateOrder = useCallback(
    async (
      withoutCard?: boolean,
      installments?: number,
      changeFor?: number
    ) => {
      const items: OrderItemRequestDTO[] = cart.map((cartProduct) => {
        return {
          product: cartProduct._id,
          quantity: cartProduct.quantity,
          note: cartProduct.note,
          ...(cartProduct.additionals && {
            additionals: cartProduct.additionals.map((additional) => {
              return {
                _id: additional._id,
                removable: additional.removable,
                options: additional.options.map((option) => {
                  return {
                    _id: option._id,
                    quantity: option.quantity,
                  };
                }),
              };
            }),
          }),
          items: (cartProduct?.items || []).map((comboItem) => ({
            product: comboItem._id,
            quantity: comboItem.quantity,
            note: comboItem?.note || '',
            unitPrice: comboItem.price,
            ...(comboItem?.additionals && {
              additionals: comboItem.additionals.map((additional) => {
                return {
                  _id: additional._id,
                  removable: additional.removable || false,
                  options: additional.options.map((option) => {
                    return {
                      _id: option._id,
                      quantity: option.quantity,
                    };
                  }),
                };
              }),
            }),
          })),
        };
      });

      const card = {
        number: formData.payment?.card?.number,
        cvv: formData.payment?.card?.cvv,
        expirationDate: formData.payment?.card?.expirationDate,
        flag: formData.payment?.card?.flag,
        holderName: formData.payment?.card?.holderName,
      };

      const customerData = isAuthenticated
        ? {
            name: customer?.name,
            phoneNumber: customer?.phoneNumber,
            _id: customer?._id,
            document: customer?.document,
            email: customer?.email,
          }
        : {
            ...parsedFormData.customer,
          };

      const requestDto: OrderRequestDTO = {
        ...parsedFormData,
        _id: orderId.current,
        customer: customerData,
        payment: {
          ...parsedFormData.payment,
          installments: Number(installments),
          method: parsedFormData.payment?.method,
          ...(parsedFormData.payment?.method ===
            OrderPaymentMethodEnum.cash_in_shipping && {
            change: formData.payment?.changeFor,
          }),
          ...(formData.payment?.card &&
            !withoutCard && {
              card,
            }),
          ...(changeFor && {
            changeFor,
          }),
        },
        shipping: {
          ...parsedFormData.shipping,
          option: `${parsedFormData.shipping?.option?.code}:${parsedFormData.shipping?.option?.name}`,
        },
        items,
      };

      const response = await createOrder(requestDto);

      if (response.error) {
        setSubmitOrderLoading(null);

        const { tag } = response.error;

        if (tag === 'ORDER_ITEMS_REQUIRED') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description: 'É obrigatorio ter ao menos um produto no carrinho!',
          });

          push(routes.home(store?.tag));

          return;
        }

        if (tag === 'DOCUMENT_REQUIRED') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description: 'Documento é obrigatorio ser informado!',
          });

          return;
        }

        if (tag === 'NOTE_REQUIRED') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description:
              'Precisa ser adicionado uma observação geral no pedido!',
          });

          return;
        }

        if (tag === 'CARD_ERROR') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description: 'O cartão inserido é inválido!',
          });

          return;
        }

        if (tag === 'CUSTOMER_PHONE_NUMBER_INVALID') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description: 'O celular inserido é inválido!',
          });

          return;
        }

        if (tag === 'MIN_ADDITIONAL_OPTIONS_REQUIRED') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description:
              'Algum complemento esta com a quantidade menor que a quantidade minima!',
          });

          return;
        }

        if (tag === 'OUT_OF_STOCK_PRODUCT') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description:
              'Algum produto adicionado tem a quantidade maior que a disponível no estoque, adicione os produtos novamente!',
          });

          localStorage.removeItem(storages.CART(store?.tag));
          setCart([]);
          push(routes.home(store?.tag));

          return;
        }

        if (tag === 'ORDER_MIN_AMOUNT_REQUIRED') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description:
              'O valor total do pedido está menor que o valor mínimo!',
          });

          return;
        }

        if (tag === 'ORDER_MAX_INSTALLMENTS_EXCEEDED') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description: 'O número de parcelas do pedido ultrapassou o limite!',
          });

          return;
        }

        if (tag === 'PRODUCT_NOT_FOUND') {
          toast({
            status: 'error',
            title: 'Ops, ocorreu um erro',
            description:
              'Por favor, adicione os produtos novamente ao carrinho!',
          });

          cleanCart();

          push(routes.home(store?.tag));

          return;
        }

        toast({
          status: 'error',
          title: 'Ops, ocorreu um erro',
          description: 'Ao tentar finalizar o pedido!',
        });
      } else {
        setCookie(
          null,
          storages.ORDER(store?.tag),
          JSON.stringify({
            _id: response.data._id,
            code: response.data.code,
            pixCode: response.data.payment?.pix?.code,
            status: response.data.status,
            shippingAmount: response.data.shipping?.option?.price || 0,
          }),
          {
            path: '/',
          }
        );

        track('Purchase');

        push(
          routes.checkout.order(query.storeTag as string, response.data.code)
        );
      }
    },
    [
      cart,
      cleanCart,
      customer,
      formData,
      isAuthenticated,
      parsedFormData,
      push,
      query,
      setCart,
      store,
      toast,
      track,
    ]
  );

  return (
    <OrdersContext.Provider
      value={{
        handleCreateOrder,
        submitOrderLoading,
        setSubmitOrderLoading,
        orderTotalAmount,
        refreshOrderId,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

const useOrders = (): IOrdersContextData => {
  return useContext(OrdersContext);
};

export { OrdersProvider, useOrders };
