/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';

import { FaRegFileImage } from 'react-icons/fa';

import * as uuid from 'uuid';

import { useStore } from '~/hooks/store';
import { useMediaQuery } from '~/hooks/mediaQuery';
import { useLayouts } from '~/hooks/layouts';

import { Image } from '~/components/Image';

import { Container, PrincipalImage, SideImages, SideImage } from './styles';

import { SizeEnum } from '~/enums/SizeEnum';

import { ExpandedImage } from './ExpandedImage';

interface IProps {
  size?: SizeEnum;
  pictures: string[];
  containerId?: string;
  expandPrincipalImageOnClick?: boolean;
  darkBorder?: boolean;
}

interface IImageSize {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const ImageContainer: FC<IProps> = ({
  size = 'md',
  pictures,
  containerId,
  expandPrincipalImageOnClick,
  darkBorder,
}) => {
  const { store } = useStore();
  const { secondLayoutId } = useLayouts();

  const [expandedImage, setExpandedImage] = useState<string>('');

  const isBiggerThan1100px = useMediaQuery('(min-width: 1100px)');
  const isSmallerThan1100px = useMediaQuery('(max-width: 1100px)');
  const isSmallerThan650px = useMediaQuery('(max-width: 650px)');

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [imageSize, setImageSize] = useState<IImageSize>({
    sm: 80,
    md: 150,
    lg: 210,
    xl: 320,
  });

  const hasImage = !!(pictures && pictures[0] && pictures.length > 0);
  const multipleImages = hasImage && pictures.length > 1;

  useEffect(() => {
    if (isBiggerThan1100px) {
      setImageSize(() => ({
        sm: 80,
        md: 150,
        lg: 210,
        xl: 320,
      }));
    }

    if (isSmallerThan1100px) {
      setImageSize((prevState) => ({
        ...prevState,
        md: 150,
        lg: 210,
        xl: 300,
      }));
    }

    if (isSmallerThan650px) {
      const mdSize = store.layout === secondLayoutId ? 120 : 130;

      setImageSize((prevState) => ({
        ...prevState,
        md: mdSize,
        lg: 190,
        xl: 250,
      }));
    }
  }, [isBiggerThan1100px, isSmallerThan1100px, isSmallerThan650px]);

  return (
    <>
      <ExpandedImage image={expandedImage} setImage={setExpandedImage} />

      <Container id={containerId}>
        {multipleImages && (
          <SideImages>
            {pictures.map((image, index) => (
              <SideImage
                onClick={() => setSelectedImageIndex(index)}
                key={uuid.v4()}
                selected={selectedImageIndex === index}
                storeTheme={store.theme}
              >
                <Image
                  objectFit="contain"
                  src={image}
                  alt="Image"
                  width={60}
                  height={60}
                />
              </SideImage>
            ))}
          </SideImages>
        )}

        <PrincipalImage
          size={size}
          imageSize={imageSize[size]}
          multipleImages={multipleImages}
          storeTheme={store?.theme}
          onClick={() =>
            expandPrincipalImageOnClick &&
            hasImage &&
            setExpandedImage(pictures[selectedImageIndex])
          }
          imageButton={!!(expandPrincipalImageOnClick && hasImage)}
          darkBorder={darkBorder}
        >
          {hasImage ? (
            <Image
              objectFit="contain"
              src={pictures[selectedImageIndex]}
              alt="Image"
              width={imageSize[size]}
              height={imageSize[size]}
            />
          ) : (
            <div className="without-image">
              <FaRegFileImage />
              <p>sem imagem</p>
            </div>
          )}
        </PrincipalImage>
      </Container>
    </>
  );
};
