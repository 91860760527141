import { FC, useEffect } from 'react';

import { useRouter } from 'next/router';

export const ScrollToTop: FC = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const scrollToTop = (): void => {
      const mainElement = document.getElementById('__next');

      if (mainElement) {
        mainElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    };

    router.events.on('routeChangeComplete', scrollToTop);

    return () => {
      router.events.off('routeChangeComplete', scrollToTop);
    };
  }, [router]);

  return <>{children}</>;
};
