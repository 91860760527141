export const theme = {
  borderRadius: {
    sm: '5px',
    md: '8px',
    lg: '12px',
    xlg: '25px',
    none: '0',
  },
  spacing: '20px',
  mobileSpacing: '15px',
  maxWidth: '1300px',
  mobileWidth: '650px',
};
