import {
  FC,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import { parseCookies } from 'nookies';

import { storages } from '~/constants/storages';

import { OrderRequestDTO } from '~/dtos/OrderRequestDTO';

import { useStore } from './store';
import { useAuth } from './auth';

interface IFormContextData {
  formData: OrderRequestDTO;
  setFormData: Dispatch<SetStateAction<OrderRequestDTO>>;
  splittedCustomerName: string;
}

const FormContext = createContext({} as IFormContextData);

const FormProvider: FC = ({ children }) => {
  const { isAuthenticated, customer } = useAuth();
  const { store } = useStore();

  const [formData, setFormData] = useState<OrderRequestDTO>(
    {} as OrderRequestDTO
  );
  const [splittedCustomerName, setSplittedCustomerName] = useState<string>('');

  const {
    [storages.FORM_DATA(store?.tag)]: storagedFormData,
    [storages.CHECKOUT_TYPE(store?.tag)]: storagedCheckoutType,
  } = parseCookies();
  const parsedFormData = JSON.parse(storagedFormData || '{}');

  useEffect(() => {
    if (isAuthenticated) {
      setSplittedCustomerName(customer?.name?.split(' ')[0]);
    } else {
      setSplittedCustomerName(parsedFormData?.customer?.name?.split(' ')[0]);
    }
  }, [
    customer,
    isAuthenticated,
    parsedFormData?.customer?.name,
    storagedCheckoutType,
  ]);

  return (
    <FormContext.Provider
      value={{ formData, setFormData, splittedCustomerName }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useForm = (): IFormContextData => {
  return useContext(FormContext);
};

export { FormProvider, useForm };
