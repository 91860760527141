import { FC, HTMLAttributes } from 'react';

import { Container } from './styles';

import { SizeEnum } from '~/enums/SizeEnum';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  size: SizeEnum;
}

export const Badge: FC<IProps> = ({ size = 'sm', children, ...props }) => {
  return (
    <Container size={size} {...props} id="badge">
      {children}
    </Container>
  );
};
