import { IFormattedAddress } from '~/interfaces/IFormattedAddress';
import { IAddress } from '~/interfaces/IAddress';

export function formatAddress(address: IAddress): IFormattedAddress {
  return {
    _id: address._id,
    name: address.name,
    address: `${address.street}${
      address.number ? `, Nº ${address.number}` : ''
    }${address.neighborhood ? `, ${address.neighborhood}` : ''}${
      address.complement ? `, ${address.complement}` : ''
    }${address.city ? `, ${address.city}` : ''}${
      address.state ? `, ${address.state}` : ''
    }`,
  };
}
