import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    outline: 0;
    padding: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 5px;
      height: 8px;

      background: ${({ theme }) => theme.colors.background};
    }

    ::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.background};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 2px;
    }
  }

  html, body, #__next {
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
    font-family: 'Montserrat', sans-serif;
    overflow: auto;
    background: ${({ theme }) => theme.colors.background};
  }

  #__next {
    z-index: 0;
  }

  .maxWidth {
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.spacing};

    @media (max-width: ${({ theme }) => theme.mobileWidth}) {
      padding: 0 ${({ theme }) => theme.mobileSpacing};
    }
  }

  h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
  }

  textarea {
    font-family: 'Montserrat', sans-serif;
  }

  input {
    border: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  input[type=number] {
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }

  button:disabled {
    cursor: not-allowed;
  }

  *:focus:not(a) {
    box-shadow: none !important;
  }

  html {
    font-size: 62.5%;
  }

  @media (max-width: 1024px) {
    html {
      font-size: 50%;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 45%;
    }
  }

  #chakra-modal-chakra-drawer-global-style {
    max-width: 450px;

    @media (max-width: ${({ theme }) => theme.mobileWidth}) {
      max-height: 80%;
    }
  }

  #chakra-modal-chakra-modal-global-style {
    max-width: 500px;
    margin-right: 16px;
    margin-left: 16px;
  }

  .chakra-alert {
    padding: 15px!important;
    padding-right: 30px!important;

    .chakra-alert__icon {
      height: 21px !important;
      width: 21px !important;
      margin-right: 10px;
    }

    .chakra-alert__title {
      font-size: 15px;
      margin-bottom: 6px;
      margin-top: 4px;
    }

    .chakra-alert__desc {
      font-size: 13.5px;
    }
  }

  /* Menu do perfil */
  .css-r6z5ec {
    z-index: 99!important;
  }
`;
