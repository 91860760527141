export const darkColors = {
  colors: {
    primary: '#9173FC',
    onPrimary: '#FFFFFF',
    secondary: '#FFBB00',
    onSecondary: '#112257',
    background: '#00042C',
    onBackground: '#C3C3C3',
    success: '#69D478',
    onSuccess: '#FFFFFF',
    danger: '#F00000',
    onDanger: '#FFFFFF',
    info: '#54CEFD',
    onInfo: '#112257',
    facebook: '#4267B2',
    onFacebook: '#FFFFFF',
    secondBackground: '#112257',
    whatsapp: '#25D366',
    onWhatsapp: '#FFFFFF',
    warning: '#FB6340',
    onWarning: '#112257',
  },
};
