import styled from 'styled-components';
import { ModalBody } from '@chakra-ui/react';

export const StyledModalBody = styled(ModalBody)`
  position: relative;
  padding: 20px !important;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;

    h3 {
      font-size: 15px;
      color: ${({ theme }) => theme.colors.onBackground};
      text-transform: uppercase;
      font-weight: 700;
      line-height: 18px;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        width: 19px;
        height: 19px;
        color: ${({ theme }) => theme.colors.onBackground};

        path:not(:first-child) {
          stroke-width: 1px;
        }
      }

      span {
        font-weight: 600;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.onBackground};
      }
    }
  }
`;
