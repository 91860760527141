import { AxiosResponse } from 'axios';

import { formatOrder } from '~/utils/formatOrder';

import { IPaginationData } from '~/interfaces/IPaginationData';
import { IOrder } from '~/interfaces/IOrder';

import { OrderResponseDTO } from '~/dtos/OrderResponseDTO';
import { OrderRequestDTO } from '~/dtos/OrderRequestDTO';

import { api, IApiResponse } from './api';

export async function createOrder(
  order: OrderRequestDTO
): Promise<IApiResponse<IOrder>> {
  try {
    const response = await api.post('/orders', order);

    return {
      data: response.data,
    };
  } catch (err) {
    return {
      error: err?.response?.data,
    };
  }
}

export async function loadOrders(
  customer: string,
  params: Omit<IPaginationData, 'pagesCount'>
): Promise<OrderResponseDTO> {
  try {
    const { data: ordersResponse }: AxiosResponse<OrderResponseDTO> =
      await api.get<OrderResponseDTO>(`/orders?customer=${customer}`, {
        params: {
          ...params,
        },
      });

    const formattedResponse = ordersResponse.data.map((order) =>
      formatOrder(order)
    );

    return {
      data: formattedResponse,
      pagesCount: ordersResponse.pagesCount,
      limit: ordersResponse.limit,
      page: ordersResponse.page,
    };
  } catch (err) {
    return {
      error: err?.response?.data,
    };
  }
}
