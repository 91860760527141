import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { MdClose } from 'react-icons/md';
import { IoSearch } from 'react-icons/io5';

import { useRouter } from 'next/router';

import { useToast } from '@chakra-ui/react';

import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useFacebookPixel } from '~/hooks/facebookPixel';
import { useDebounce } from '~/hooks/debounce';
import { useClickOutside } from '~/hooks/clickOutside';

import { ImageContainer } from '~/components/ImageContainer';

import { Container, Tab } from './styles';

import { formatCurrency } from '~/utils/formatCurrency';

import { loadProducts } from '~/services/products';

import { IProduct } from '~/interfaces/IProduct';

export const SearchInput: FC = () => {
  const toast = useToast({
    status: 'error',
    isClosable: true,
    position: 'top-right',
  });
  const { store } = useStore();
  const { track } = useFacebookPixel();
  const { push } = useRouter();

  const [tabOpen, setTabOpen] = useState<boolean>(false);
  const [shouldFilter, setShouldFilter] = useState<boolean>(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  const debounceFunction = useDebounce(() => setShouldFilter(true));

  const searchRef = useRef<HTMLInputElement>(null);
  const containerRef = useClickOutside(() => setTabOpen(false));

  const closeTab = useCallback(() => {
    setTabOpen(false);
    searchRef.current.value = '';
  }, []);

  const openProduct = useCallback(
    (productId: string) => {
      setTabOpen(false);
      push(routes.product(store?.tag, productId));
    },
    [push, store?.tag]
  );

  useEffect(() => {
    (async () => {
      if (shouldFilter) {
        if (searchRef.current.value.length > 0) {
          if (!tabOpen) setTabOpen(true);
        }

        if (searchRef.current.value.length > 0) {
          const response = await loadProducts({
            name: searchRef.current.value,
          });

          if (response.error) {
            toast({
              title: 'Ops, ocorreu um erro',
              description: 'Ao tentar filtrar os produtos',
            });

            return;
          }

          setProducts(response.data);
        }

        track('Search');

        setShouldFilter(false);
      }
    })();
  }, [store?.tag, track, shouldFilter, toast, tabOpen]);

  return (
    <Container tabOpen={tabOpen} storeTheme={store?.theme} ref={containerRef}>
      <input
        type="text"
        onChange={debounceFunction}
        ref={searchRef}
        placeholder="Pesquise aqui o produto"
      />

      <div>
        {tabOpen ? (
          <button type="button" onClick={closeTab}>
            <MdClose />
          </button>
        ) : (
          <IoSearch />
        )}
      </div>

      <Tab tabOpen={tabOpen} storeTheme={store?.theme}>
        {products && products.length > 0 ? (
          products.map((product) => (
            <button
              key={product._id}
              type="button"
              className="tab-product"
              onClick={() => openProduct(product._id)}
            >
              <ImageContainer
                darkBorder
                size="sm"
                pictures={[product.pictures[0]]}
              />

              <div className="content">
                <h3 className="title">{product.name}</h3>

                <span className="price">{formatCurrency(product.price)}</span>
              </div>
            </button>
          ))
        ) : (
          <p>Nenhum produto com esse nome foi encontrado!</p>
        )}
      </Tab>
    </Container>
  );
};
