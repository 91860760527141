import styled from 'styled-components';

interface IBackgroundProps {
  image: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

export const Background = styled.div<IBackgroundProps>`
  position: relative;

  background-image: url(${(props) => `/images/backgrounds/${props.image}`});
  background-position: center;
  background-size: cover;

  width: 100%;
  height: 100%;

  @media (max-width: 700px) {
    display: none;
  }
  .background-image {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;

  height: 100%;
  width: 100%;
  max-width: 520px;
  min-width: 400px;

  box-shadow: rgb(0 0 0 / 15%) -10px 3px 15px;
  padding: 0 40px 0 40px;
  padding-bottom: 0;
  z-index: 1;

  @media (max-width: 700px) {
    max-width: none;
    padding: 20px 30px;
    min-width: 0px;

    .logo-image {
      margin-bottom: 32px;
    }
  }
`;
