import { FC } from 'react';

import { FiUser } from 'react-icons/fi';

import { useRouter } from 'next/router';

import { Menu } from '@chakra-ui/react';

import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useModal } from '~/hooks/modal';
import { useDrawer } from '~/hooks/drawer';
import { useAuth } from '~/hooks/auth';

import { SignInModal } from '~/components/Modals/SignInModal';
import { UserDataDrawer } from '~/components/Drawers/UserDataDrawer';
import { HelpDrawer } from '~/components/Drawers/HelpDrawer';

import { StyledMenuList, StyledMenuButton, MenuItem } from './styles';

interface IProps {
  atNavBottom?: boolean;
}

export const ProfileItem: FC<IProps> = ({ atNavBottom }) => {
  const router = useRouter();

  const { openModal } = useModal();
  const { isAuthenticated, signOut, customer } = useAuth();
  const { store } = useStore();
  const { openDrawer } = useDrawer();

  return (
    <Menu>
      <StyledMenuButton
        atNavBottom={atNavBottom}
        onClick={() => !isAuthenticated && openModal('Entrar', <SignInModal />)}
      >
        <button type="button" className="nav-button">
          <FiUser />

          {!atNavBottom && <p>{isAuthenticated ? 'perfil' : 'entrar'}</p>}
        </button>
      </StyledMenuButton>

      {isAuthenticated && (
        <StyledMenuList atNavBottom={atNavBottom}>
          <MenuItem onClick={() => router.push(routes.orders(store?.tag))}>
            pedidos
          </MenuItem>

          <MenuItem
            onClick={() =>
              openDrawer(
                `${customer.name?.split(' ')[0]}, aqui estão seus dados`,
                <UserDataDrawer />
              )
            }
          >
            meus dados
          </MenuItem>

          <MenuItem onClick={() => openDrawer('Ajuda', <HelpDrawer />)}>
            ajuda
          </MenuItem>

          <MenuItem onClick={signOut}>sair</MenuItem>
        </StyledMenuList>
      )}
    </Menu>
  );
};
