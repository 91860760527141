import styled from 'styled-components';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';

interface IProps {
  storeTheme: StoreThemeEnum;
}

export const Container = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
    margin-bottom: 50px;
  }
`;

export const Product = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 8px;

  .remove-add-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      background: ${(props) =>
        props.storeTheme === StoreThemeEnum.dark
          ? props.theme.colors.primary
          : '#fff'};
      border-radius: ${({ theme }) => theme.borderRadius.sm};

      padding: 7px;

      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        width: 18px;
        height: 18px;

        stroke-width: 15px;

        color: ${(props) =>
          props.storeTheme === StoreThemeEnum.dark
            ? '#fff'
            : props.theme.colors.onBackground};
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-left: 20px;

  .description {
    margin-bottom: 9px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;

      h2 {
        color: ${({ theme }) => theme.colors.onBackground};
        margin-bottom: 5px;
        font-size: 15px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    button {
      margin-right: 9px;
    }
  }
`;

export const NoteTooltip = styled.div`
  position: relative;
  z-index: 99 !important;

  svg {
    margin-left: 8px;
    height: 22px;
    width: 22px;
    color: ${({ theme }) => theme.colors.info};
  }

  .tooltip-container {
    position: absolute;
    right: -70px;
    bottom: calc(100% + 12px);

    width: 215px;
    z-index: 99;
    background: #fff;
    padding: 12px;

    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: ${({ theme }) => theme.borderRadius.sm};

    &:before {
      background: #fff;
      content: '';
      display: block;
      height: 25px;
      width: 25px;
      right: 86px;
      position: absolute;
      bottom: 10px;
      transform: rotate(-45deg) translateY(25px);
      z-index: -5;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
    }
  }
`;

export const Complements = styled.ul`
  li {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.onBackground};

    svg {
      width: 19px;
      height: 19px;

      margin-right: 4px;
      margin-bottom: 3px;
    }
  }
`;
