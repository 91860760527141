import styled, { css } from 'styled-components';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';

interface IContainerProps {
  tabOpen: boolean;
  storeTheme: StoreThemeEnum;
}

interface ITabProps {
  tabOpen: boolean;
  storeTheme: StoreThemeEnum;
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  width: 100%;

  margin: 0 30px;

  @media (max-width: ${({ theme }) => theme.mobileWidth}) {
    margin: 0;
  }

  > input {
    width: 100%;
    height: 40px;

    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding: 0 40px 0 15px;

    font-size: 15px;
    color: ${({ theme }) => theme.colors.onBackground};
    font-weight: 500;
    letter-spacing: 0.3px;

    background: ${({ theme }) => theme.colors.secondBackground};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

    &::placeholder {
      color: ${({ theme }) => theme.colors.onBackground};
    }
  }

  > div {
    display: flex;

    height: 100%;

    position: absolute;
    right: 0;
    top: 0;

    > svg {
      width: 19px;
      height: 19px;

      margin: auto 15px auto 0;
      color: ${({ theme }) => theme.colors.onBackground};
    }

    > button {
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }

      > svg {
        width: 19px;
        height: 19px;

        margin: auto 15px auto 0;
        color: #5e5e5e;
      }
    }
  }

  ${(props) =>
    props.tabOpen &&
    css`
      input {
        border-radius: ${({ theme }) =>
          `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`};
        border-bottom: 1px solid;
        border-color: ${props.storeTheme === StoreThemeEnum.dark
          ? props.theme.colors.onBackground
          : '#f1f1f1'};
      }
    `}
`;

export const Tab = styled.div<ITabProps>`
  z-index: 2;

  display: ${(props) => (props.tabOpen ? 'flex' : 'none !important')};
  flex-direction: column;

  width: 100% !important;
  height: auto !important;
  max-height: 400px;
  padding: 16px !important;
  background: ${({ theme }) => theme.colors.secondBackground};
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

  overflow: auto;
  margin-top: 40px;

  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm}`};

  @media (max-width: ${({ theme }) => theme.mobileWidth}) {
    max-height: 300px;
  }

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .tab-product {
    display: flex;
    flex-direction: row;

    margin-bottom: 16px;
    padding-bottom: 16px;

    border-bottom: 1px solid #f1f1f1;
    border-color: ${(props) =>
      props.storeTheme === StoreThemeEnum.dark
        ? props.theme.colors.onBackground
        : '#f1f1f1'};

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }

    transition: 0.3s opacity;

    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      margin-left: 16px;
      margin-top: 4px;

      .title {
        font-size: 14px;
        line-height: 14px;
        text-align: start;

        margin-bottom: 8px;

        color: ${({ theme }) => theme.colors.onBackground};
      }

      .price {
        font-size: 14px;
        font-weight: 700;
        color: #707070;
        line-height: 14px;
        text-align: start;
      }
    }
  }

  p {
    font-size: 13px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.onBackground};
  }
`;
