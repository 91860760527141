import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';

import { removeAccent } from '~/utils/removeAccent';

import { IProduct } from '~/interfaces/IProduct';
import { ICategory } from '~/interfaces/ICategory';

export enum SortTypeEnum {
  bySmallerPrice = 'bySmallerPrice',
  byBiggerPrice = 'byBiggerPrice',
  byLettersAZ = 'byLettersAZ',
  byLettersZA = 'byLettersZA',
}

interface ISortProductsContextData {
  sortProducts(type: SortTypeEnum): void;
  selectedSort?: SortTypeEnum;
  bestSellingProducts: IProduct[];
  setBestSellingProducts: Dispatch<SetStateAction<IProduct[]>>;
  categoriesWithSubcategories: ICategory[];
  setCategoriesWithSubcategories: Dispatch<SetStateAction<ICategory[]>>;
  withoutCategoryProducts: IProduct[];
  setWithoutCategoryProducts: Dispatch<SetStateAction<IProduct[]>>;
  promotionalProducts: IProduct[];
  setPromotionalProducts: Dispatch<SetStateAction<IProduct[]>>;
}

const SortProductsContext = createContext({} as ISortProductsContextData);

const SortProductsProvider: FC = ({ children }) => {
  const [promotionalProducts, setPromotionalProducts] = useState<IProduct[]>(
    []
  );

  const [bestSellingProducts, setBestSellingProducts] = useState<IProduct[]>(
    []
  );

  const [categoriesWithSubcategories, setCategoriesWithSubcategories] =
    useState<ICategory[]>([]);

  const [withoutCategoryProducts, setWithoutCategoryProducts] = useState<
    IProduct[]
  >([]);

  const [selectedSort, setSelectedSort] = useState<SortTypeEnum>(null);

  function sort(sortType: SortTypeEnum, array: IProduct[]): IProduct[] {
    let sortedProducts: IProduct[] = [];

    if (sortType === SortTypeEnum.bySmallerPrice) {
      sortedProducts = array.sort((a, b): any => {
        if (a?.price > b?.price) return 1;
        if (a?.price < b?.price) return -1;

        return 0;
      });
    }

    if (sortType === SortTypeEnum.byBiggerPrice) {
      sortedProducts = array.sort((a, b): any => {
        if (a?.price < b?.price) return 1;
        if (a?.price > b?.price) return -1;

        return 0;
      });
    }

    if (sortType === SortTypeEnum.byLettersAZ) {
      sortedProducts = array.sort((a, b) => {
        if (
          removeAccent(a.name?.toLowerCase()) <
          removeAccent(b.name?.toLowerCase())
        )
          return -1;
        if (
          removeAccent(a.name?.toLowerCase()) >
          removeAccent(b.name?.toLowerCase())
        )
          return 1;

        return 0;
      });
    }

    if (sortType === SortTypeEnum.byLettersZA) {
      sortedProducts = array.sort((a, b) => {
        if (
          removeAccent(a.name?.toLowerCase()) >
          removeAccent(b.name?.toLowerCase())
        )
          return -1;
        if (
          removeAccent(a.name?.toLowerCase()) <
          removeAccent(b.name?.toLowerCase())
        )
          return 1;

        return 0;
      });
    }

    return sortedProducts;
  }

  const sortPromotionalProducts = useCallback(
    (sortType: SortTypeEnum) => {
      const sortedProducts = sort(sortType, promotionalProducts);

      setPromotionalProducts([...sortedProducts]);
    },
    [promotionalProducts]
  );

  const sortBestSellingProducts = useCallback(
    (sortType: SortTypeEnum) => {
      const sortedProducts = sort(sortType, bestSellingProducts);

      setBestSellingProducts([...sortedProducts]);
    },
    [bestSellingProducts]
  );

  const sortCategoriesProducts = useCallback(
    (sortType: SortTypeEnum) => {
      const newArr = [...categoriesWithSubcategories];

      newArr.forEach((category) => {
        const findCategoryIndex = newArr.findIndex(
          (c) => c._id === category._id
        );

        newArr[findCategoryIndex].products = sort(
          sortType,
          newArr[findCategoryIndex].products
        );

        if (category.subcategories?.length > 0) {
          newArr[findCategoryIndex].subcategories.forEach((subcategorie) => {
            const findSubcategoryIndex = newArr[
              findCategoryIndex
            ].subcategories.findIndex((s) => s._id === subcategorie._id);

            newArr[findCategoryIndex].subcategories[
              findSubcategoryIndex
            ].products = sort(
              sortType,
              newArr[findCategoryIndex].subcategories[findSubcategoryIndex]
                .products
            );
          });
        }
      });

      setCategoriesWithSubcategories(newArr);
    },
    [categoriesWithSubcategories]
  );

  const sortWithoutCategoryProducts = useCallback(
    (sortType: SortTypeEnum) => {
      const sortedProducts = sort(sortType, withoutCategoryProducts);

      setWithoutCategoryProducts([...sortedProducts]);
    },
    [withoutCategoryProducts]
  );

  const sortProducts = useCallback(
    (type: SortTypeEnum) => {
      if (selectedSort === type) return;

      setSelectedSort(type);

      sortPromotionalProducts(type);
      sortBestSellingProducts(type);
      sortCategoriesProducts(type);
      sortWithoutCategoryProducts(type);
    },
    [
      selectedSort,
      sortBestSellingProducts,
      sortCategoriesProducts,
      sortPromotionalProducts,
      sortWithoutCategoryProducts,
    ]
  );

  return (
    <SortProductsContext.Provider
      value={{
        sortProducts,
        selectedSort,
        bestSellingProducts,
        setBestSellingProducts,
        categoriesWithSubcategories,
        setCategoriesWithSubcategories,
        withoutCategoryProducts,
        setWithoutCategoryProducts,
        promotionalProducts,
        setPromotionalProducts,
      }}
    >
      {children}
    </SortProductsContext.Provider>
  );
};

const useSortProducts = (): ISortProductsContextData => {
  return useContext(SortProductsContext);
};

export { SortProductsProvider, useSortProducts };
