import styled, { css } from 'styled-components';

import { SizeEnum } from '~/enums/SizeEnum';

interface IContainerProps {
  size: SizeEnum;
}

export const Container = styled.div<IContainerProps>`
  display: inline-block;

  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  color: ${({ theme }) => theme.colors.onPrimary};
  font-weight: 600;

  ${(props) =>
    props.size === 'sm'
      ? css`
          padding: 2px 7px;
          font-size: 11px;
        `
      : props.size === 'md'
      ? css`
          padding: 3px 10px;
          font-size: 13px;
        `
      : css`
          padding: 4px 13px;
          font-size: 15px;
        `}
`;
