import styled, { ColorScheme, css } from 'styled-components';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';

interface IContainerProps {
  size: ButtonSize;
  backgroundColor?: ColorScheme;
  textColor?: ColorScheme;
  fillAllSpace?: boolean;
  outline?: boolean;
}

export const Container = styled.div<IContainerProps>`
  button {
    display: inline-block;
    background: ${({ theme, backgroundColor }) =>
      theme.colors[backgroundColor]};
    color: ${({ theme, textColor }) => theme.colors[textColor]};
    font-weight: 700;

    border-radius: ${({ theme }) => theme.borderRadius.sm};

    padding: ${(props) =>
      props.size === 'sm'
        ? '6px 15px'
        : props.size === 'md'
        ? '10px 22px'
        : props.size === 'lg'
        ? '13px 28px'
        : '16px 30px'};
    font-size: ${(props) =>
      props.size === 'sm'
        ? '11.5px'
        : props.size === 'md'
        ? '13.5px'
        : props.size === 'lg'
        ? ' 15px'
        : '16px'};
    letter-spacing: ${(props) =>
      props.size === 'sm' ? '0.5px' : props.size === 'md' ? '0.6px' : '0.7px'};

    transition: filter 0.5s, background 0.3s;

    &:hover:not(:disabled) {
      filter: brightness(0.8);
    }

    &:disabled {
      opacity: 0.6;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    a {
      background: ${({ theme, backgroundColor }) =>
        theme.colors[backgroundColor]};
      color: ${({ theme, textColor }) => theme.colors[textColor]};
    }
  }

  ${(props) =>
    props.fillAllSpace &&
    css`
      width: 100%;

      button {
        width: 100%;
      }
    `}

  ${(props) =>
    props.outline &&
    css`
      button {
        background: none;
        border: 2px solid ${props.theme.colors[props.backgroundColor]};
        color: ${props.theme.colors[props.textColor]};
        padding: ${props.size === 'sm'
          ? '4px 13px'
          : props.size === 'md'
          ? '8px 20px'
          : props.size === 'lg'
          ? '11px 26px'
          : '14px 28px'};
      }
    `}
`;
