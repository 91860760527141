import { FC } from 'react';

import { RiInformationLine } from 'react-icons/ri';
import { ImArrowLeft2 } from 'react-icons/im';
import { HiMenuAlt2 } from 'react-icons/hi';
import { FiShoppingCart } from 'react-icons/fi';
import { BiHomeAlt } from 'react-icons/bi';

import Link from 'next/link';

import { useToast } from '@chakra-ui/react';

import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useMediaQuery } from '~/hooks/mediaQuery';
import { useDrawer } from '~/hooks/drawer';
import { useCart } from '~/hooks/cart';

import { SearchInput } from '~/components/Header/SearchInput';
import { InfoDrawer } from '~/components/Drawers/InfoDrawer';
import { CartDrawer } from '~/components/Drawers/CartDrawer';

import { Container, Content, NavItems, StoreIsOpenTopBar } from './styles';

import { ProfileItem } from '../ProfileItem';
import { LogoContainer } from '../LogoContainer';

interface IProps {
  backButton?: boolean;
}

export const Header: FC<IProps> = ({ backButton }) => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    status: 'warning',
  });
  const { store, isOpen } = useStore();
  const { cartTotals } = useCart();
  const { openDrawer } = useDrawer();
  const isBiggerThan650px = useMediaQuery('(min-width: 651px)');

  function openCart(): void {
    if (isOpen) {
      openDrawer('Resumo do pedido', <CartDrawer />);
    } else {
      toast({
        title: 'Ops,',
        description: 'A loja está fechada!',
      });
    }
  }

  return (
    <Container>
      {isBiggerThan650px && (
        <StoreIsOpenTopBar isOpen={isOpen}>
          <span>{isOpen ? 'estamos abertos' : 'estamos fechados'}</span>
        </StoreIsOpenTopBar>
      )}

      <Content className="maxWidth">
        {!isBiggerThan650px &&
          (backButton ? (
            <button type="button" className="menu-button">
              <Link href={routes.home(store?.tag)}>
                <ImArrowLeft2 />
              </Link>
            </button>
          ) : (
            <button
              type="button"
              className="menu-button"
              onClick={() =>
                openDrawer('Informações da empresa', <InfoDrawer />)
              }
            >
              <HiMenuAlt2 />
            </button>
          ))}

        <Link href={routes.home(store.tag)} passHref>
          <LogoContainer size="sm" logo={store?.logo} />
        </Link>

        {!isBiggerThan650px && (
          <div className="opening-status-container">
            <div className="opening-status">
              <div className={`status-ball ${isOpen ? '' : 'closed'}`} />
              <span>{isOpen ? 'Aberto' : 'Fechado'}</span>
            </div>
          </div>
        )}

        <div className="input-div">
          <SearchInput />
        </div>

        {isBiggerThan650px && (
          <NavItems className="nav-items">
            <Link href={routes.home(store.tag)}>
              <div className="nav-button">
                <BiHomeAlt />
                <p>inicio</p>
              </div>
            </Link>

            <button
              type="button"
              onClick={() =>
                openDrawer('Informações da empresa', <InfoDrawer />)
              }
              className="nav-button"
            >
              <RiInformationLine />
              <p>sobre</p>
            </button>

            <button
              type="button"
              onClick={() => openCart()}
              className="nav-button"
            >
              {cartTotals.quantity > 0 && <span>{cartTotals.quantity}</span>}
              <FiShoppingCart />
              <p>carrinho</p>
            </button>

            <ProfileItem />
          </NavItems>
        )}
      </Content>
    </Container>
  );
};
