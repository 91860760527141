import { FC, useCallback } from 'react';

import { FiShoppingCart } from 'react-icons/fi';

import { setCookie } from 'nookies';
import { useToast } from '@chakra-ui/react';

import { storages } from '~/constants/storages';

import { useStore } from '~/hooks/store';
import { useModal } from '~/hooks/modal';
import { useFacebookPixel } from '~/hooks/facebookPixel';
import { useDrawer } from '~/hooks/drawer';
import { useCart } from '~/hooks/cart';

import { NoteModal } from '~/components/Modals/NoteModal';
import { Button } from '~/components/Button';

import { CartFooter, EmptyAlert } from './styles';
import { formatCurrency } from '~/utils/formatCurrency';

import { CheckoutTypeEnum } from '~/enums/CheckoutTypeEnum';

import { CartProduct } from '../../Products/CartProduct';

export const CartDrawer: FC = () => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    status: 'warning',
  });
  const { openModal } = useModal();
  const { closeDrawer } = useDrawer();
  const { track } = useFacebookPixel();
  const { cart, cartTotals } = useCart();
  const { store } = useStore();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (store?.payment?.minAmount > cartTotals.price) {
        toast({
          title: `O valor total do pedido precisa ser maior ou igual que ${formatCurrency(
            store.payment?.minAmount
          )}`,
        });

        return;
      }

      closeDrawer();
      openModal('Observação', <NoteModal />);
      track('InitiateCheckout');
      setCookie(
        null,
        storages.CHECKOUT_TYPE(store?.tag),
        CheckoutTypeEnum.register,
        {
          path: '/',
        }
      );
    },
    [
      cartTotals.price,
      closeDrawer,
      openModal,
      store.payment?.minAmount,
      store?.tag,
      toast,
      track,
    ]
  );

  return (
    <form onSubmit={handleSubmit}>
      {cart && cart.length > 0 && (
        <CartFooter>
          <div>
            <span>{cartTotals.quantity} produtos</span>
            <strong>{formatCurrency(cartTotals.price)}</strong>
          </div>
          <Button type="submit" backgroundColor="success" textColor="onSuccess">
            Finalizar pedido
          </Button>
        </CartFooter>
      )}

      {!cart ||
        (cart.length <= 0 && (
          <EmptyAlert>
            <FiShoppingCart />
            <p>Carrinho vazio</p>
          </EmptyAlert>
        ))}

      {cart.map((cartProduct, index) => (
        <CartProduct
          key={`cart-product-${cartProduct.tempId}`}
          product={cartProduct}
          index={index}
        />
      ))}
    </form>
  );
};
