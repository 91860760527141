/* eslint-disable react/jsx-no-bind */
import { CSSProperties, FC, ReactNode, useRef, useState } from 'react';

import { IoChevronDown } from 'react-icons/io5';

import useMedia from 'use-media';
import { ColorScheme, useTheme } from 'styled-components';
import { Collapse, Tooltip } from '@chakra-ui/react';

import { useStore } from '~/hooks/store';

import { Container, Header, Content } from './styles';

interface AccordionProps {
  title: string;
  alwaysOpen?: boolean;
  startsOpen?: boolean;
  headerContent?: ReactNode;
  contentStyle?: CSSProperties;
  color?: ColorScheme;
  id?: string;
  isDisabled?: boolean;
  disabledTooltip?: string;
  size?: 'sm' | 'md';
  invertColors?: boolean;
}

export const Accordion: FC<AccordionProps> = ({
  title,
  id,
  children,
  alwaysOpen,
  startsOpen,
  headerContent,
  contentStyle,
  isDisabled,
  disabledTooltip,
  color = 'secondary',
  size = 'md',
  invertColors,
}) => {
  const { store } = useStore();
  const theme = useTheme();
  const maxWidthMobile = useMedia({ maxWidth: theme.mobileWidth });

  const iconContainerRef = useRef<HTMLDivElement>(null);

  const [isContentOpen, setIsContentOpen] = useState(
    startsOpen || alwaysOpen || false
  );

  function toggleContentVisibility(event): void {
    if (!children) return;

    if (alwaysOpen || !event.target.dataset.cantoggle) return;

    setIsContentOpen((prevState) => {
      const newState = !prevState;

      if (iconContainerRef.current) {
        iconContainerRef.current.style.transform = `rotate(${
          newState ? '180deg' : '0'
        })`;
      }

      return newState;
    });
  }

  function renderIcon(content: any): any {
    if (maxWidthMobile) {
      return <>{content}</>;
    }

    return (
      <Tooltip
        label={isContentOpen ? 'Esconder conteúdo' : 'Mostrar conteúdo'}
        hasArrow
      >
        {content}
      </Tooltip>
    );
  }

  return (
    <Container id={id}>
      <div>
        <Tooltip label={disabledTooltip} hasArrow isDisabled={!isDisabled}>
          <Header
            alwaysOpen={alwaysOpen}
            onClick={toggleContentVisibility}
            data-cantoggle
            color={color}
            isDisabled={isDisabled}
            storeTheme={store?.theme}
            isContentOpen={isContentOpen}
            size={size}
            invertColors={invertColors}
          >
            <h3 data-cantoggle>{title}</h3>

            {!!headerContent && <div>{headerContent}</div>}

            {!isDisabled &&
              !!children &&
              !alwaysOpen &&
              renderIcon(
                <div ref={iconContainerRef} data-cantoggle>
                  <IoChevronDown data-cantoggle />
                </div>
              )}
          </Header>
        </Tooltip>

        <Collapse
          in={
            !children
              ? false
              : !isDisabled && (!!children && alwaysOpen ? true : isContentOpen)
          }
          animateOpacity
        >
          <Content style={contentStyle}>{children}</Content>
        </Collapse>
      </div>
    </Container>
  );
};
