import styled, { css } from 'styled-components';

interface IContainerProps {
  imageSize: number;
}

export const Container = styled.a<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 130px;
    min-width: 110px;
  }

  .without-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => css`
      width: ${`${props.imageSize}px`};
      height: ${`${props.imageSize}px`};
    `}

    border-radius: ${({ theme }) => theme.borderRadius.md};
    background: ${({ theme }) => theme.colors.secondBackground};

    color: ${({ theme }) => theme.colors.onBackground};
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
  }
`;
