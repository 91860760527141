import styled from 'styled-components';

export const Container = styled.form`
  > footer {
    margin-top: 20px;
  }
`;

export const AddressList = styled.ul`
  margin-bottom: 20px;

  li {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }

    strong {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 8px;
      color: ${({ theme }) => theme.colors.onBackground};
    }

    p {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 8px;
      color: ${({ theme }) => theme.colors.onBackground};
    }

    > div {
      display: flex;
      align-items: center;
    }
  }
`;
