import styled from 'styled-components';

export const CartFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;

  z-index: 2;

  width: 100%;
  background: #fff;
  background: ${({ theme }) => theme.colors.secondBackground};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 20px;
  box-shadow: 0px -10px 6px rgba(0, 0, 0, 0.03);

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.onBackground};
    }

    strong {
      font-size: 15px;
      font-weight: 700;
      line-height: 19px;
      color: ${({ theme }) => theme.colors.onBackground};
    }
  }
`;

export const EmptyAlert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 100px;
  width: 100%;

  svg {
    width: 85px;
    height: 85px;

    color: #d8d8d8;
  }

  p {
    margin-top: 24px;

    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #d8d8d8;
  }
`;
