export const lightColors = {
  colors: {
    primary: '#112257',
    onPrimary: '#FFFFFF',
    secondary: '#FFBB00',
    onSecondary: '#112257',
    background: '#F2F2F4',
    onBackground: '#112257',
    success: '#69D478',
    onSuccess: '#FFFFFF',
    danger: '#F00',
    onDanger: '#FFFFFF',
    info: '#54CEFD',
    onInfo: '#112257',
    facebook: '#4267B2',
    onFacebook: '#FFFFFF',
    secondBackground: '#fff',
    whatsapp: '#25D366',
    onWhatsapp: '#FFFFFF',
    warning: '#FB6340',
    onWarning: '#112257',
  },
};
