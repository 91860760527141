import styled from 'styled-components';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';

interface IImageContainerProps {
  image?: string;
}

interface IContainerProps {
  storeTheme: StoreThemeEnum;
}

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  z-index: 999999;
  background: rgba(0, 0, 0, 0.4);
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  max-width: 520px;
  max-height: 520px;
  margin: 0 16px;

  background: ${(props) =>
    props.storeTheme === StoreThemeEnum.dark
      ? props.theme.colors.secondBackground
      : '#bbb'};
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  @media (max-width: 520px) {
    max-height: 420px;
  }

  @media (max-width: 450px) {
    max-height: 360px;
  }

  @media (max-width: 390px) {
    max-height: 320px;
  }
`;

export const ImageContainer = styled.div<IImageContainerProps>`
  width: 100%;
  height: 100%;

  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
