import { FC } from 'react';

import { RiWhatsappFill } from 'react-icons/ri';
import { MdLocationOn, MdPhoneInTalk, MdEmail } from 'react-icons/md';
import { IoBagHandle } from 'react-icons/io5';
import {
  FaTruck,
  FaFacebookSquare,
  FaInstagram,
  FaMoneyBillAlt,
} from 'react-icons/fa';
import { BsCreditCard2FrontFill, BsFillCreditCardFill } from 'react-icons/bs';
import { AiFillDollarCircle } from 'react-icons/ai';

import Link from 'next/link';

import { useStore } from '~/hooks/store';

import { LogoContainer } from '~/components/LogoContainer';

import { Container, InfoList } from './styles';

import { maskPhoneNumber } from '~/utils/masks';
import { formatCurrency } from '~/utils/formatCurrency';

import { StoreContactTypeEnum } from '~/enums/StoreContactTypeEnum';
import { OrderShippingMethodEnum } from '~/enums/OrderShippingMethodEnum';
import { OrderPaymentMethodEnum } from '~/enums/OrderPaymentMethodEnum';

import { Accordion } from '../../Accordion';
import packageJson from '../../../../package.json';

export const InfoDrawer: FC = () => {
  const { store } = useStore();

  return (
    <Container>
      <div className="description">
        <LogoContainer logo={store?.logo} />

        <h1>{store?.name}</h1>

        {!!store?.description && <p>{store?.description}</p>}
      </div>

      <div>
        {store?.formattedAddresses?.length > 0 && (
          <Accordion
            size="sm"
            title="Localizações"
            contentStyle={{ background: '#fff' }}
          >
            <InfoList>
              {store.formattedAddresses.map((address) => (
                <li key={address._id}>
                  <MdLocationOn />

                  <div>
                    {!!address.name && <strong>{address.name}</strong>}

                    <span>{address.address}</span>
                  </div>
                </li>
              ))}
            </InfoList>
          </Accordion>
        )}

        {store.shipping?.availableMethods.includes(
          OrderShippingMethodEnum.delivery
        ) &&
          store?.shipping?.postalCodesRanges?.length > 0 && (
            <Accordion
              size="sm"
              title="Onde entregamos"
              contentStyle={{ background: '#fff' }}
            >
              <InfoList>
                {store.shipping.postalCodesRanges.map((postalCodesRange) => (
                  <li className="col" key={postalCodesRange._id}>
                    <strong>{postalCodesRange.name}</strong>
                    <span>
                      Taxa de entrega: {formatCurrency(postalCodesRange.price)}
                    </span>
                    <span>
                      Tempo estimado: {postalCodesRange.estimatedTime} min
                    </span>
                  </li>
                ))}
              </InfoList>
            </Accordion>
          )}

        {store?.shipping?.availableMethods?.length > 0 && (
          <Accordion
            size="sm"
            title="Formas de entrega"
            contentStyle={{ background: '#fff' }}
          >
            <InfoList>
              {store?.shipping?.availableMethods.includes(
                OrderShippingMethodEnum.delivery
              ) && (
                <li>
                  <FaTruck />
                  <span>Delivery</span>
                </li>
              )}

              {store?.shipping?.availableMethods.includes(
                OrderShippingMethodEnum.withdraw
              ) && (
                <li>
                  <IoBagHandle />
                  <span>Cliente retira</span>
                </li>
              )}
            </InfoList>
          </Accordion>
        )}

        {store?.payment?.availableMethods?.length > 0 && (
          <Accordion
            size="sm"
            title="Formas de pagamento"
            contentStyle={{ background: '#fff' }}
          >
            <InfoList>
              {store?.payment?.availableMethods.includes(
                OrderPaymentMethodEnum.cash_in_withdraw
              ) && (
                <li>
                  <FaMoneyBillAlt />
                  <span>Dinheiro</span>
                </li>
              )}

              {store?.payment?.availableMethods.includes(
                OrderPaymentMethodEnum.card_online
              ) && (
                <li>
                  <BsCreditCard2FrontFill />
                  <span>Cartão de crédito online</span>
                </li>
              )}

              {store?.payment?.availableMethods.includes(
                OrderPaymentMethodEnum.pix_in_shipping
              ) && (
                <li>
                  <AiFillDollarCircle />
                  <span>Pix na entrega</span>
                </li>
              )}

              {store?.payment?.availableMethods.includes(
                OrderPaymentMethodEnum.card_in_shipping
              ) && (
                <li>
                  <BsFillCreditCardFill />
                  <span>Cartão na entrega</span>
                </li>
              )}
            </InfoList>
          </Accordion>
        )}

        {store?.contacts?.length > 0 && (
          <Accordion
            size="sm"
            title="Central de atendimento"
            contentStyle={{ background: '#fff' }}
          >
            <InfoList>
              {store.contacts.map((contact) => (
                <li key={`${contact.type}-${contact.value}`}>
                  {contact.type === StoreContactTypeEnum.email ? (
                    <MdEmail />
                  ) : contact.type === StoreContactTypeEnum.phone_number ? (
                    <MdPhoneInTalk />
                  ) : (
                    <RiWhatsappFill />
                  )}

                  <span>
                    {contact.type === StoreContactTypeEnum.email
                      ? contact.value
                      : maskPhoneNumber(contact.value)}
                  </span>
                </li>
              ))}
            </InfoList>
          </Accordion>
        )}
      </div>

      {Object.values(store?.socialNetworks || {}).length > 0 && (
        <footer>
          <div className="socials">
            {store.socialNetworks.facebook && (
              <Link href={store.socialNetworks.facebook} passHref>
                <a target="_blank">
                  <FaFacebookSquare />
                </a>
              </Link>
            )}

            {store.socialNetworks.instagram && (
              <Link href={store.socialNetworks.instagram} passHref>
                <a target="_blank">
                  <FaInstagram />
                </a>
              </Link>
            )}
          </div>

          <span>nossas redes sociais</span>

          <span style={{ marginTop: '16px' }}>v{packageJson.version}</span>
        </footer>
      )}
    </Container>
  );
};
