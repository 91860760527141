import { IProduct } from '~/interfaces/IProduct';

export const minPriceCombo = (product: IProduct): number => {
  let minPrice = 0;

  minPrice = (product?.comboJourneys || []).reduce((acc, nextValue) => {
    if (nextValue.selectBy === 'subcategories') {
      return (
        acc +
        nextValue.subcategories.reduce(
          (minValueSubcategory: number, nextSubcategory) => {
            const value = (nextSubcategory.products || []).reduce(
              (minValue, currentValue) => {
                if (minValue === 0) return currentValue.priceInCombo;
                else return Math.min(minValue, currentValue.priceInCombo);
              },
              0
            );
            if (minValueSubcategory === 0) return value;
            if (value === 0) return minValueSubcategory;
            return Math.min(value, minValueSubcategory);
          },
          0
        )
      );
    } else if (nextValue.selectBy === 'categories') {
      return (
        acc +
        nextValue.categories.reduce(
          (minValueCategory: number, nextCategory) => {
            let value = (nextCategory.products || []).reduce(
              (minValue, currentValue) => {
                if (minValue === 0) return currentValue.priceInCombo;
                else return Math.min(minValue, currentValue.priceInCombo);
              },
              0
            );

            value += (nextCategory.subcategories || []).reduce(
              (minValue, currentValue) => {
                let minsSubcategoriesInCategory = [];

                minsSubcategoriesInCategory.push(
                  currentValue.products.reduce((minSub, currentSub) => {
                    if (minSub === 0) return currentSub.priceInCombo;
                    else return Math.min(minSub, currentSub.priceInCombo);
                  }, 0)
                );

                const subValue = minsSubcategoriesInCategory.reduce(
                  (minBetweenSubs, currentMin, minIndex) => {
                    if (minIndex === 0) {
                      return currentMin;
                    } else if (minBetweenSubs > currentMin) {
                      return currentMin;
                    } else {
                      return minBetweenSubs * (currentValue?.minQuantity || 1);
                    }
                  },
                  0
                );

                if (subValue === 0) return minValue;
                if (minValue === 0) return subValue;
                return Math.min(subValue, minValue);
              },
              0
            );

            if (minValueCategory === 0) return value;
            if (value === 0) return minValueCategory;
            return Math.min(minValueCategory, value);
          },
          0
        )
      );
    } else {
      return (
        acc +
        (nextValue.products || []).reduce((minValue, currentValue) => {
          if (minValue === 0) return currentValue.priceInCombo;
          else return Math.min(minValue, currentValue.priceInCombo);
        }, 0)
      );
    }
  }, 0);

  return minPrice;
};
