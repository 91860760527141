import { useEffect } from 'react';

import Head from 'next/head';
import { AppProps } from 'next/app';

import { setCookie } from 'nookies';
import { FormLayout } from '~/layouts/FormLayout';
import { DefaultLayout } from '~/layouts/DefaultLayout';

import { storages } from '~/constants/storages';

import { StoreProvider } from '~/hooks/store';
import { FacebookPixelProvider } from '~/hooks/facebookPixel';
import { AppProvider } from '~/hooks';

import { GlobalStyle } from '~/styles/global';

import { api } from '~/services/api';

import { IStore } from '~/interfaces/IStore';

function MyApp({
  Component,
  pageProps,
  router,
}: AppProps<{ store: IStore }>): JSX.Element {
  const { store } = pageProps;

  const splittedRoute = router.pathname.split('/');

  const productDetailsRoute = splittedRoute[2] === 'products';
  const categoryDetailsRoute = splittedRoute[2] === 'categories';

  const formRoute =
    splittedRoute[2] === 'auth' ||
    splittedRoute[2] === 'register' ||
    splittedRoute[2] === 'checkout';

  const defaultRoute =
    splittedRoute[2] === undefined ||
    splittedRoute[2] === 'products' ||
    splittedRoute[2] === 'categories' ||
    splittedRoute[2] === 'orders';

  const Layout = formRoute
    ? FormLayout
    : defaultRoute
    ? DefaultLayout
    : ({ children }) => <>{children}</>;

  const layoutProps =
    productDetailsRoute || categoryDetailsRoute
      ? {
          backButton: true,
        }
      : {};

  useEffect(() => {
    if (store) {
      setCookie(undefined, storages.STORE_ID, store._id, {
        path: '/',
      });
      Object.assign(api.defaults.headers, { 'x-store': store._id });
    }
  }, [store]);

  return (
    <StoreProvider store={store}>
      <FacebookPixelProvider store={store}>
        <AppProvider>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
          </Head>

          <GlobalStyle />

          <Layout {...layoutProps}>
            {/* @ts-ignore */}
            <Component {...pageProps} />
          </Layout>
        </AppProvider>
      </FacebookPixelProvider>
    </StoreProvider>
  );
}

export default MyApp;
