import { FC } from 'react';

import { ChakraProvider } from '@chakra-ui/react';

import { ProductDetailsProvider } from '~/hooks/productDetails';

import { ScrollToTop } from '~/components/ScrollToTop';

import { ThemeConfigsProvider } from './themeConfigs';
import { SortProductsProvider } from './sortProducts';
import { OrdersProvider } from './orders';
import { ModalProvider } from './modal';
import { LayoutsProvider } from './layouts';
import { FormProvider } from './form';
import { DrawerProvider } from './drawer';
import { CartProvider } from './cart';
import { AuthProvider } from './auth';

export const AppProvider: FC = ({ children }) => {
  return (
    <ChakraProvider resetCSS={false}>
      <ScrollToTop>
        <LayoutsProvider>
          <SortProductsProvider>
            <ThemeConfigsProvider>
              <AuthProvider>
                <ProductDetailsProvider>
                  <FormProvider>
                    <CartProvider>
                      <OrdersProvider>
                        <ModalProvider>
                          <DrawerProvider>{children}</DrawerProvider>
                        </ModalProvider>
                      </OrdersProvider>
                    </CartProvider>
                  </FormProvider>
                </ProductDetailsProvider>
              </AuthProvider>
            </ThemeConfigsProvider>
          </SortProductsProvider>
        </LayoutsProvider>
      </ScrollToTop>
    </ChakraProvider>
  );
};
