import styled, { css } from 'styled-components';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';
import { SizeEnum } from '~/enums/SizeEnum';

interface IPrincipalImageProps {
  size: SizeEnum;
  imageSize: number;
  multipleImages: boolean;
  storeTheme: StoreThemeEnum;
  imageButton?: boolean;
  darkBorder?: boolean;
}

interface ISideImageProps {
  selected: boolean;
  storeTheme: StoreThemeEnum;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: 920px) {
    flex-direction: column;
  }

  .arrow-button {
    svg {
      width: 40px;
      height: 40px;

      color: ${({ theme }) => theme.colors.primary};

      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const PrincipalImage = styled.div<IPrincipalImageProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${(props) =>
    props.storeTheme === StoreThemeEnum.light
      ? '2px solid #ddd'
      : props.darkBorder && '1px solid #bbb'};
  background: ${(props) =>
    props.storeTheme === StoreThemeEnum.dark
      ? props.theme.colors.secondBackground
      : '#f1f1f1'};

  min-width: ${(props) => `${props.imageSize}px`};
  min-height: ${(props) => `${props.imageSize}px`};

  ${(props) =>
    props.imageButton &&
    css`
      cursor: pointer;
      transition: 0.3s opacity;

      &:hover {
        opacity: 0.95;
      }
    `}

  .without-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      color: #bbb;
    }

    p {
      color: #bbb;
      text-transform: uppercase;
    }

    ${(props) =>
      props.size === 'sm'
        ? css`
            svg {
              width: 22px;
              height: 22px;
              margin-bottom: 5px;
            }

            p {
              font-size: 8px;
            }
          `
        : props.size === 'md'
        ? css`
            svg {
              width: 32px;
              height: 32px;
              margin-bottom: 6px;
            }

            p {
              font-size: 10px;
            }
          `
        : props.size === 'lg'
        ? css`
            svg {
              width: 38px;
              height: 38px;
              margin-bottom: 10px;
            }

            p {
              font-size: 11px;
            }
          `
        : css`
            svg {
              width: 40px;
              height: 40px;
              margin-bottom: 10px;
            }

            p {
              font-size: 12px;
            }
          `}
  }
`;

export const SideImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 16px;

  @media (max-width: 920px) {
    flex-direction: row;
    margin-bottom: 16px;
  }
`;

export const SideImage = styled.div<ISideImageProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${(props) =>
    `2px solid ${
      props.storeTheme === StoreThemeEnum.light
        ? '#ddd'
        : props.theme.colors.secondBackground
    }`};
  background: ${(props) =>
    props.storeTheme === StoreThemeEnum.dark
      ? props.theme.colors.secondBackground
      : '#f1f1f1'};

  min-width: 70px;
  min-height: 70px;

  margin-bottom: 16px;

  cursor: pointer;
  transition: border 0.3s;

  @media (max-width: 920px) {
    margin-bottom: 0;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${({ theme }) => theme.colors.primary};
    `}
`;
