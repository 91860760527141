export enum OrderPaymentMethodEnum {
  cash_in_shipping = 'cash_in_shipping',
  card_in_shipping = 'card_in_shipping',
  pix_in_shipping = 'pix_in_shipping',
  cash_in_withdraw = 'cash_in_withdraw',
  card_in_withdraw = 'card_in_withdraw',
  pix_in_withdraw = 'pix_in_withdraw',
  card_online = 'card_online',
  pix_online = 'pix_online',
  boleto_online = 'boleto_online',
}

export enum OrderPaymentFormattedMethodEnum {
  cash_in_shipping = 'Dinheiro na entrega',
  card_in_shipping = 'Cartão na entrega',
  pix_in_shipping = 'Pix na entrega',
  cash_in_withdraw = 'Dinheiro na retirada',
  card_in_withdraw = 'Cartão na retirada',
  pix_in_withdraw = 'Pix na retirada',
  card_online = 'Cartão online',
  pix_online = 'Pix online',
  boleto_online = 'Boleto online',
}
