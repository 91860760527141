/* eslint-disable import/no-cycle */
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { setCookie, destroyCookie, parseCookies } from 'nookies';
import decode from 'jwt-decode';
import { useToast } from '@chakra-ui/react';

import { storages } from '~/constants/storages';

import { formatCustomer } from '~/utils/formatCustomer';

import { api } from '~/services/api';

import { ICustomer } from '~/interfaces/ICustomer';

import { CustomerSignInRequestDTO } from '~/dtos/CustomerSignInRequestDTO';
import { CustomerRequestDTO } from '~/dtos/CustomerRequestDTO';

export interface DecodedUser {
  customerId: string;
}

interface IAuthContextData {
  signIn(credentials: CustomerSignInRequestDTO): Promise<void>;
  isAuthenticated: boolean;
  customer: ICustomer | null;
  signUp(data: CustomerRequestDTO): Promise<void>;
  signOut(): void;
  updateCustomer(data: CustomerRequestDTO): Promise<void>;
  loadUserData(): Promise<void>;
}

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: FC = ({ children }) => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    status: 'error',
  });

  const [customer, setCustomer] = useState<ICustomer | null>(null);

  const isAuthenticated = useMemo(() => !!customer, [customer]);

  const signOut = useCallback(() => {
    destroyCookie(null, storages.AUTH_TOKEN, { path: '/' });

    setCustomer(null);
  }, []);

  const loadUserData = useCallback(async () => {
    const { [storages.AUTH_TOKEN]: token } = parseCookies();

    if (token) {
      try {
        const { customerId } = decode<DecodedUser>(token);

        const response = await api.get(`/customers/${customerId}`);

        if (response.data) {
          const formattedCustomer = formatCustomer(response.data);

          setCustomer(formattedCustomer);
        } else {
          signOut();
        }
      } catch {
        signOut();
      }
    }
  }, [signOut]);

  useEffect(() => {
    (async () => {
      await loadUserData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async (credentials: CustomerSignInRequestDTO): Promise<void> => {
      try {
        const response = await api.post('/customer-auth/signin', credentials);

        const { token, customer: customerData } = response.data;

        setCookie(undefined, storages.AUTH_TOKEN, token, {
          maxAge: 60 * 60 * 24, // 24 hours
          path: '/',
        });

        api.defaults.headers.Authorization = `Bearer ${token}`;

        const formattedCustomer = formatCustomer(customerData);

        setCustomer(formattedCustomer);
      } catch (err) {
        if (err?.response?.data?.tag === 'CUSTOMER_UNAUTHORIZED') {
          toast({
            title: 'Ops, ocorreu um erro ao fazer o login!',
            description:
              'Parece que seu celular e/ou senha está(ão) incorreto(s).',
          });
        } else {
          toast({
            title: 'Ops, ocorreu um erro ao fazer o login!',
            description:
              'Não foi possível realizar o seu login, recarregue a página e tente novamente.',
          });
        }

        throw err;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signUp = useCallback(
    async (data: CustomerRequestDTO): Promise<void> => {
      try {
        await api.post('/customer-auth/signup', data);
      } catch (err) {
        if (err.response?.data?.tag === 'CUSTOMER_EMAIL_ALREADY_EXISTS') {
          toast({
            title: 'Ops, ocorreu um erro',
            description: 'Ja existe um cadastro com esse email!',
          });
          return;
        }

        if (err.response?.data?.tag === 'CUSTOMER_DOCUMENT_ALREADY_EXISTS') {
          toast({
            title: 'Ops, ocorreu um erro',
            description: 'Ja existe um cadastro com esse documento!',
          });
          return;
        }

        toast({
          title: 'Ops, ocorreu um erro',
          description: 'Ao tentar cadastrar os dados!',
        });

        throw err;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateCustomer = useCallback(
    async (data: CustomerRequestDTO) => {
      const response = await api.put(`/customers/${customer._id}`, data);

      if (response.data) {
        const formattedCustomer = formatCustomer(response.data);
        setCustomer(formattedCustomer);
      }
    },
    [customer?._id]
  );

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        signUp,
        customer,
        updateCustomer,
        signOut,
        loadUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
