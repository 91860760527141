import styled from 'styled-components';
import { MenuList, MenuButton } from '@chakra-ui/react';

interface IAtNavBottom {
  atNavBottom?: boolean;
}

export const StyledMenuButton = styled(MenuButton)<IAtNavBottom>`
  padding: ${(props) => props.atNavBottom && '0 !important'};
`;

export const StyledMenuList = styled(MenuList)<IAtNavBottom>`
  padding: 0 !important;
  width: ${(props) => props.atNavBottom && '160px'};
`;

export const MenuItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100% !important;
  padding: 9px 35px;

  border-bottom: 1px solid #f2f2f2;

  font-weight: 600;
  font-size: 13.5px;
  color: #112257;

  transition: 0.3s opacity;

  &:hover {
    opacity: 0.6;
  }

  &:last-child {
    border-bottom: 0;
  }
`;
