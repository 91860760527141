/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/button-has-type */
import { ButtonHTMLAttributes, FC } from 'react';

import { ColorScheme, CSSProperties } from 'styled-components';

import { ButtonSize, Container } from './styles';

import { Spinner } from '../Spinner';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  backgroundColor?: ColorScheme;
  textColor?: ColorScheme;
  fillAllSpace?: boolean;
  containerStyle?: CSSProperties;
  outline?: boolean;
  loading?: boolean;
}

export const Button: FC<IProps> = ({
  type = 'button',
  size = 'md',
  backgroundColor = 'primary',
  textColor = 'onPrimary',
  fillAllSpace,
  containerStyle,
  outline,
  loading,
  children,
  ...props
}) => {
  return (
    <Container
      size={size}
      backgroundColor={backgroundColor}
      textColor={textColor}
      fillAllSpace={fillAllSpace}
      style={containerStyle}
      outline={outline}
    >
      <button
        type={type}
        {...props}
        disabled={loading || props.disabled}
        onClick={loading ? () => {} : props.onClick}
      >
        {loading ? <Spinner size={size === 'xl' ? 'lg' : size} /> : children}
      </button>
    </Container>
  );
};
