import { FC } from 'react';

import { Header } from '~/components/Header';

interface IProps {
  backButton: boolean;
}

export const DefaultLayout: FC<IProps> = ({ children, backButton }) => {
  return (
    <>
      <Header backButton={backButton} />

      {children}
    </>
  );
};
