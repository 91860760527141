import { FC } from 'react';

import Head from 'next/head';

import { useStore } from '~/hooks/store';

export const StoreSEO: FC = () => {
  const { store } = useStore();

  return (
    <Head>
      <meta name="author" content={store?.name || 'Menu Fácil'} />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="pt_BR" />

      <meta name="image" content={store?.logo || ''} />
      <meta property="og:image" content={store?.logo || ''} />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="500" />

      <meta name="description" content={store?.description || ''} />
      <meta property="og:description" content={store?.description || ''} />

      <meta property="og:title" content={store?.name || ''} />
      <meta property="og:site_name" content={store?.name || ''} />

      <meta name="url" content={store?.url} />
      <meta property="og:url" content={store?.url} />

      <title>{store?.title || 'Loja do Menu Fácil'}</title>

      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={store?.favicon || './images/favicon.png'}
      />
    </Head>
  );
};
