import {
  FC,
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useCallback,
} from 'react';

import { IModalHandles, Modal } from '~/components/Modals/Modal';

interface IModalContextData {
  openModal(
    modalTitle: string,
    modalContent: ReactNode,
    _noCloseOnclickOut?: boolean
  ): void;
  closeModal(): void;
}

const ModalContext = createContext({} as IModalContextData);

const ModalProvider: FC = ({ children }) => {
  const modalRef = useRef<IModalHandles>(null);

  const [content, setContent] = useState<ReactNode>(null);
  const [title, setTitle] = useState<string>('');
  const [noCloseOnclickOut, setNoCloseOnclickOut] = useState(false);

  const openModal = useCallback(
    (modalTitle, modalContent, _noCloseOnclickOut = false) => {
      modalRef.current?.open();
      setTitle(modalTitle);
      setContent(modalContent);
      setNoCloseOnclickOut(_noCloseOnclickOut);
    },
    []
  );

  const closeModal = useCallback(() => {
    modalRef.current?.close();
    setTitle('');
    setContent(null);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Modal ref={modalRef} title={title} noCloseOnclickOut={noCloseOnclickOut}>
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

const useModal = (): IModalContextData => {
  return useContext(ModalContext);
};

export { ModalProvider, useModal };
