import { Dispatch, FC, SetStateAction } from 'react';

import { useStore } from '~/hooks/store';
import { useClickOutside } from '~/hooks/clickOutside';

import { Background, Container, ImageContainer } from './styles';

interface IProps {
  image: string;
  setImage: Dispatch<SetStateAction<string>>;
}

export const ExpandedImage: FC<IProps> = ({ image, setImage }) => {
  const containerRef = useClickOutside(() => setImage(''));

  const { store } = useStore();

  return (
    image && (
      <Background>
        <Container ref={containerRef} storeTheme={store?.theme}>
          <ImageContainer image={image} />
        </Container>
      </Background>
    )
  );
};
