export const routes = {
  notFound: '/not-found',

  auth: {
    phoneVerification: (storeTag: string): string =>
      `/${storeTag}/auth/phone-verification`,
    signIn: (storeTag: string): string => `/${storeTag}/auth/signin`,
    noRecord: (storeTag: string): string => `/${storeTag}/auth/no-record`,
  },

  home: (storeTag: string): string => `/${storeTag}`,
  product: (storeTag: string, productId: string): string =>
    `/${storeTag}/products/${productId}`,
  category: (storeTag: string, categoryId: string): string =>
    `/${storeTag}/categories/${categoryId}`,
  orders: (storeTag: string): string => `/${storeTag}/orders`,

  register: {
    name: (storeTag: string): string => `/${storeTag}/register/name`,
    phoneNumber: (storeTag: string): string =>
      `/${storeTag}/register/phone-number`,
    email: (storeTag: string): string => `/${storeTag}/register/email`,
    document: (storeTag: string): string => `/${storeTag}/register/document`,
    address: (storeTag: string): string => `/${storeTag}/register/address`,
    sendCode: (storeTag: string): string => `/${storeTag}/register/send-code`,
    confirmPhoneNumber: (storeTag: string): string =>
      `/${storeTag}/register/confirm-phone-number`,
    password: (storeTag: string): string => `/${storeTag}/register/password`,
  },

  checkout: {
    shippingMethod: (storeTag: string): string =>
      `/${storeTag}/checkout/shipping-method`,
    paymentPlace: (storeTag: string): string =>
      `/${storeTag}/checkout/payment-place`,
    deliveryOptions: (storeTag: string): string =>
      `/${storeTag}/checkout/delivery-options`,
    paymentType: (storeTag: string): string =>
      `/${storeTag}/checkout/payment-type`,
    change: (storeTag: string): string => `/${storeTag}/checkout/change`,
    order: (storeTag: string, orderCode: string): string =>
      `/${storeTag}/checkout/orders/${orderCode}`,
    card: (storeTag: string): string => `/${storeTag}/checkout/card`,
    addresses: (storeTag: string): string => `/${storeTag}/checkout/addresses`,
  },
};
