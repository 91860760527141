import {
  OrderFormattedStatusEnum,
  OrderStatusEnum,
} from '~/enums/OrderStatusEnum';
import { OrderPaymentFormattedMethodEnum } from '~/enums/OrderPaymentMethodEnum';

import { IOrder } from '~/interfaces/IOrder';

import { formatTime } from './formatTime';
import { formatDate } from './formatDate';
import { formatCurrency } from './formatCurrency';
import { formatAddress } from './formatAddress';

export const getStatusColor: Record<OrderStatusEnum, string> = {
  approved: '#69D478',
  awaiting_approval: '#FFBB00',
  canceled: '#F00000',
  rejected: '#F00000',
  delivered: '#54CEFD',
  finished: '#69D478',
  processing: '#FFBB00',
  shipped: '#54CEFD',
  shipping_problem: '#F00000',
  awaiting_payment: '#FFBB00',
};

export function formatOrder(order: IOrder): IOrder {
  const statusColor = getStatusColor[order.status];
  const formattedStatus = OrderFormattedStatusEnum[order.status];

  const formattedCreatedAtTime = formatTime(new Date(order.createdAt));

  const formattedApprovedAt =
    order.approvedAt && formatDate(new Date(order.approvedAt));
  const formattedRejectedAt =
    order.rejectedAt && formatDate(new Date(order.rejectedAt));
  const formattedCreatedAt =
    order.createdAt && formatDate(new Date(order.createdAt));

  const formattedSubtotal = formatCurrency(order.subtotal);
  const formattedDiscount = formatCurrency(order.discount);
  const fornattedTotal = formatCurrency(order.total);

  const orderShippingFormattedPrice = formatCurrency(
    order.shipping?.option?.price || 0
  );

  const orderPaymentFormattedMethod =
    OrderPaymentFormattedMethodEnum[order.payment.method];

  const orderShippingFormattedAddress =
    order?.shipping?.address && formatAddress(order?.shipping?.address);

  return {
    ...order,
    formattedStatus,
    statusColor,
    formattedApprovedAt,
    formattedRejectedAt,
    formattedCreatedAtTime,
    formattedCreatedAt,
    formattedSubtotal,
    formattedDiscount,
    fornattedTotal,
    payment: {
      ...order.payment,
      formattedMethod: orderPaymentFormattedMethod,
    },
    shipping: {
      ...order.shipping,
      formattedAddress: orderShippingFormattedAddress,
      option: {
        ...order.shipping.option,
        formattedPrice: orderShippingFormattedPrice,
      },
    },
  };
}
