/* eslint-disable import/no-cycle */
import { parseCookies } from 'nookies';
import axios, { AxiosInstance } from 'axios';

import { storages } from '~/constants/storages';
import { env } from '~/constants/env';

import { IPaginationData } from '~/interfaces/IPaginationData';

export interface IApiResponse<Data = any> {
  data?: Data;
  paginationData?: IPaginationData;
  error?: any;
}

export function setupApi(ctx = undefined): AxiosInstance {
  const {
    [storages.STORE_ID]: storagedStoreId,
    [storages.AUTH_TOKEN]: storagedToken,
  } = parseCookies(ctx);

  const api = axios.create({
    baseURL: env.API_URI,
    headers: {
      ...(storagedStoreId && { 'x-store': storagedStoreId }),
      ...(storagedToken && { Authorization: `Bearer ${storagedToken}` }),
    },
  });

  return api;
}

export const api = setupApi();
