import styled from 'styled-components';

interface IInputContainerProps {
  error: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 700px;

  label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11.5px;
    color: ${({ theme }) => theme.colors.onBackground};

    margin-bottom: 5px;
  }
`;

export const InputContainer = styled.div<IInputContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  input {
    width: 100%;

    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: 14px 12px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2) !important;

    font-size: 15px;
    color: ${({ theme }) => theme.colors.onBackground};
    font-weight: 500;
    letter-spacing: 0.3px;
    background: ${({ theme }) => theme.colors.secondBackground};

    border: ${(props) =>
      props.error
        ? `1px solid ${props.theme.colors.danger}`
        : '1px solid #edf2f7 #e2e8f0'};

    &[type='password'] {
      padding-right: 40px;
    }

    &::placeholder {
      color: #bbb;
      font-weight: 400;
      font-size: 15px;
    }
  }

  button {
    position: absolute;
    right: 12px;
    margin: auto 0;

    svg {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 20px;
      width: 20px;

      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .spinner-container {
    position: absolute;
    right: 12px;
    margin: auto 0;
  }
`;
