import { api, IApiResponse } from './api';

export async function checkStoreIsOpen(): Promise<IApiResponse<boolean>> {
  try {
    const { data } = await api.get('/stores/check-is-open');

    return {
      data,
    };
  } catch (err) {
    return {
      error: err?.response?.data,
    };
  }
}
