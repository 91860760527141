import styled from 'styled-components';
import { DrawerBody } from '@chakra-ui/react';

export const StyledDrawerBody = styled(DrawerBody)`
  position: relative;
  padding: 20px !important;

  header {
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    padding: 20px;
    z-index: 99;
    background: ${({ theme }) => theme.colors.background};
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #ccc;

    h3 {
      font-size: 15px;
      color: ${({ theme }) => theme.colors.onBackground};
      text-transform: uppercase;
      font-weight: 700;
      line-height: 18px;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        width: 18px;
        height: 18px;
        color: ${({ theme }) => theme.colors.onBackground};

        path:not(:first-child) {
          stroke-width: 1px;
        }
      }

      span {
        font-weight: 600;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.onBackground};
      }
    }
  }

  .content {
    margin-top: 59px;
  }
`;
