import { FC, createContext, useContext, useState, useCallback } from 'react';

import { IAdditional } from '~/interfaces/IAdditional';

interface IProductDetailsContextData {
  selectedAdditional: IAdditional;
  selectAdditional(additional): void;
  additionalsWithError: string[];
  addAdditionalWithError(additionalId: string): void;
  removeAdditionalWithError(additionalId: string): void;
}

const ProductDetailsContext = createContext({} as IProductDetailsContextData);

const ProductDetailsProvider: FC = ({ children }) => {
  const [selectedAdditional, setSelectedAdditional] = useState<IAdditional>(
    {} as IAdditional
  );
  const [additionalsWithError, setAdditionalsWithError] = useState<string[]>(
    []
  );

  const addAdditionalWithError = useCallback((additionalId: string) => {
    setAdditionalsWithError((prevState) => [...prevState, additionalId]);
  }, []);

  const removeAdditionalWithError = useCallback(
    (additionalId: string) => {
      if (additionalsWithError.includes(additionalId)) {
        const newAdditionalsWithError = additionalsWithError.filter(
          (add) => add !== additionalId
        );

        setAdditionalsWithError(newAdditionalsWithError);
      }
    },
    [additionalsWithError]
  );

  const selectAdditional = useCallback((additional: IAdditional) => {
    setSelectedAdditional(additional);
  }, []);

  return (
    <ProductDetailsContext.Provider
      value={{
        selectedAdditional,
        selectAdditional,
        additionalsWithError,
        addAdditionalWithError,
        removeAdditionalWithError,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};

const useProductDetails = (): IProductDetailsContextData => {
  return useContext(ProductDetailsContext);
};

export { ProductDetailsProvider, useProductDetails };
